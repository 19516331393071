import { Route, Routes } from "react-router-dom";

import Logout from "components/Logout/Logout";
import SignupPage from "containers/SignupPage/SignupPage";
import VerifyEmailPage from "containers/VerifyEmailPage/VerifyEmailPage";
import UnsubscribeServicesPage from "containers/UnsubscribePage/UnsubscribeServicesPage";
import UnsubscribeAllPage from "containers/UnsubscribePage/UnsubscribeAllPage";

const PAGE_TITLE_PREFIX = "StatusVista |";

export const OAUTH_ROUTE = "/oauth";

export const ROUTES = Object.freeze({
  ACCOUNT: {
    pageTitle: `${PAGE_TITLE_PREFIX} Account`,
    path: "/account",
    anonymous: false,
  },
  HOME: {
    pageTitle: `${PAGE_TITLE_PREFIX} Dashboard`,
    path: "/",
    anonymous: false,
  },
  LOGOUT: {
    pageTitle: `${PAGE_TITLE_PREFIX} Logout`,
    path: "/logout",
    anonymous: false,
  },
  DONATIONS: {
    pageTitle: `${PAGE_TITLE_PREFIX} Donations`,
    path: "/donations",
    anonymous: false,
  },
  NOTIFICATIONS: {
    pageTitle: `${PAGE_TITLE_PREFIX} Notifications`,
    path: "/notifications",
    anonymous: false,
  },
  PRIVACY: {
    pageTitle: `${PAGE_TITLE_PREFIX} Privacy Policy`,
    path: "/privacy",
    anonymous: false,
  },
  SIGNUP: {
    pageTitle: `${PAGE_TITLE_PREFIX} Sign Up`,
    path: "/signup",
    anonymous: false,
  },
  SLACK_AUTH: {
    pageTitle: `${PAGE_TITLE_PREFIX} Authorize Slack`,
    path: `${OAUTH_ROUTE}/slack`,
    anonymous: false,
  },
  TERMS: {
    pageTitle: `${PAGE_TITLE_PREFIX} Terms and Conditions`,
    path: "/terms",
    anonymous: false,
  },
  UNSUBSCRIBE: {
    pageTitle: `${PAGE_TITLE_PREFIX} Unsubscribe`,
    path: "/unsubscribe",
    anonymous: true,
  },
  UNSUBSCRIBE_ALL: {
    pageTitle: `${PAGE_TITLE_PREFIX} Unsubscribe`,
    path: "/unsubscribe-all",
    anonymous: true,
  },
  VERIFY_EMAIL: {
    pageTitle: `${PAGE_TITLE_PREFIX} Verify Email`,
    path: "/verify-email",
    anonymous: true,
  },
});

export function AnonymousRoutes() {
  return (
    <Routes>
      <Route path={ROUTES.LOGOUT.path} element={<Logout />} />
      <Route path={ROUTES.SIGNUP.path} element={<SignupPage />} />
      <Route
        path={ROUTES.UNSUBSCRIBE.path}
        element={<UnsubscribeServicesPage />}
      />
      <Route
        path={ROUTES.UNSUBSCRIBE_ALL.path}
        element={<UnsubscribeAllPage />}
      />
      <Route path={ROUTES.VERIFY_EMAIL.path} element={<VerifyEmailPage />} />
    </Routes>
  );
}
