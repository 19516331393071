import { useState } from "react";
import { useQueryClient } from "react-query";
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Divider,
  FormControlLabel,
  Grid,
  Menu,
  MenuItem,
  Stack,
  Switch,
  Typography,
} from "@mui/material";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";

import { useStatusData } from "contexts/status";
import { useUpdateNotificationServicesMutation } from "lib/network";

import ButtonLoader from "components/ButtonLoader/ButtonLoader";
import ServicesButton from "components/ServicesButton/ServicesButton";

function ServiceItem({ service, updateStatus }) {
  const queryClient = useQueryClient();
  const { isLoading: isUpdateSubscriptionLoading, mutate: updateNotification } =
    useUpdateNotificationServicesMutation();

  const handleChange = async (_, newChecked) => {
    // cancel queries for situations where window is not focused and user clicks
    // a subscription toggle. without this, there's a race condition where the
    // update returns before `status` response and causes subscription state to
    // be out of sync.
    await queryClient.cancelQueries();

    updateStatus(service.identifier, {
      ...service,
      notification_subscription: { enabled: newChecked },
    });

    updateNotification({
      services: [{ enabled: newChecked, identifier: service.identifier }],
    });
  };

  const {
    notification_subscription: { enabled },
  } = service;

  return (
    <Grid
      container
      sx={{
        alignItems: "center",
        overflow: "hidden",
        py: 1,
        flexWrap: "nowrap",
      }}
    >
      <Grid item sx={{ flex: "1 1 auto !important", overflow: "hidden" }}>
        <Box
          sx={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}
        >
          {service.title}
        </Box>
      </Grid>
      <Grid
        item
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "end",
          ml: 1,
        }}
      >
        <FormControlLabel
          label={enabled ? "On" : "Off"}
          control={
            <Switch
              disabled={isUpdateSubscriptionLoading}
              checked={enabled}
              onChange={handleChange}
            />
          }
          sx={{
            width: 80,
            minWidth: 80,
          }}
        />
      </Grid>
    </Grid>
  );
}

export default function ServiceSettings() {
  const [bulkActionsAnchorEl, setBulkActionsAnchorEl] = useState();
  const bulkActionsOpen = !!bulkActionsAnchorEl;
  const { status, updateStatus } = useStatusData();
  const { isLoading: isSubscribeToAllLoading, mutate: subscribeToAll } =
    useUpdateNotificationServicesMutation();
  const { isLoading: isUnsubscribeFromAllLoading, mutate: unsubscribeFromAll } =
    useUpdateNotificationServicesMutation();

  const handleBulkActionsMenuClick = (event) => {
    setBulkActionsAnchorEl(event.currentTarget);
  };

  const handleBulkActionsMenuClose = (event) => {
    setBulkActionsAnchorEl();
  };

  const handleSubscribeAllClick = () => {
    subscribeToAll({
      services: status.map(({ identifier }) => ({
        enabled: true,
        identifier,
      })),
    });
    handleBulkActionsMenuClose();
  };

  const handleUnsubscribeAllClick = () => {
    unsubscribeFromAll({
      services: status.map(({ identifier }) => ({
        enabled: false,
        identifier,
      })),
    });
    handleBulkActionsMenuClose();
  };

  const hasServices = !!status.length;
  const isBulkActionLoading =
    isSubscribeToAllLoading || isUnsubscribeFromAllLoading;

  return (
    <Box>
      <Stack
        direction="row"
        sx={{ justifyContent: "space-between", mb: hasServices ? 0 : 1 }}
      >
        <Typography variant="h6">Service Settings</Typography>
        {hasServices && (
          <Button
            id="bulk-actions-button"
            disabled={isBulkActionLoading}
            startIcon={<MenuOpenIcon />}
            size="small"
            onClick={handleBulkActionsMenuClick}
          >
            Bulk actions
            {isBulkActionLoading && <ButtonLoader />}
          </Button>
        )}
      </Stack>
      <Menu
        id="bulk-actions-menu"
        anchorEl={bulkActionsAnchorEl}
        open={bulkActionsOpen}
        onClose={handleBulkActionsMenuClose}
        MenuListProps={{
          "aria-labelledby": "bulk-actions-button",
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <MenuItem
          disabled={
            isSubscribeToAllLoading ||
            status.every((s) => s.notification_subscription.enabled)
          }
          onClick={handleSubscribeAllClick}
        >
          Subscribe to all
        </MenuItem>
        <MenuItem
          disabled={
            isUnsubscribeFromAllLoading ||
            status.every((s) => !s.notification_subscription.enabled)
          }
          onClick={handleUnsubscribeAllClick}
        >
          Unsubscribe from all
        </MenuItem>
      </Menu>
      {!hasServices ? (
        <Alert severity="info">
          <AlertTitle>No services</AlertTitle>
          You'll need to add some services to configure notifications.
          <Box sx={{ mt: 2 }}>
            <ServicesButton
              buttonProps={{
                variant: "outlined",
              }}
            />
          </Box>
        </Alert>
      ) : (
        <Box>
          {status.map((service, idx) => (
            <Box key={idx}>
              <ServiceItem service={service} updateStatus={updateStatus} />
              {idx < status.length - 1 && <Divider />}
            </Box>
          ))}
        </Box>
      )}
    </Box>
  );
}
