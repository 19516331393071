import {
  Box,
  Button,
  Grid,
  LinearProgress,
  linearProgressClasses,
  Skeleton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import moment from "moment-timezone";

import {
  useSubscriptionQuery,
  useSubscriptionPortalSessionQuery,
} from "lib/network";
import { TIME_FORMATS } from "lib/time";
import { capitalize } from "lib/text";

import ButtonLoader from "components/ButtonLoader/ButtonLoader";
import SubscriptionPlans from "components/SubscriptionPlans/SubscriptionPlans";
import Descriptor from "components/Typography/Descriptor";

function Usage({ title, usage }) {
  const { description, max, used } = usage;
  const progressValue = Math.min(Math.round((used / max) * 100), 100);
  let color;

  if (progressValue < 50) {
    color = "success";
  } else if (progressValue < 66) {
    color = "info";
  } else if (progressValue <= 99) {
    color = "warning";
  } else if (progressValue >= 100) {
    color = "error";
  }

  return (
    <Descriptor
      heading={title}
      details={
        <Box>
          <Stack direction="row" sx={{ alignItems: "center", mb: 0.25 }}>
            {used} / {max === -1 ? "unlimited" : max}
            <Tooltip arrow title={description}>
              <InfoIcon
                sx={{ ml: 1, color: "text.secondary", fontSize: "1rem" }}
              />
            </Tooltip>
          </Stack>
          {max !== -1 && (
            <LinearProgress
              variant="determinate"
              value={progressValue}
              color={color}
              sx={{
                height: 8,
                borderRadius: 5,
                [`& .${linearProgressClasses.bar}`]: {
                  borderRadius: 5,
                },
              }}
            />
          )}
        </Box>
      }
    />
  );
}

export default function Subscription() {
  const { data: subscriptionData, isLoading: isSubscriptionLoading } =
    useSubscriptionQuery();
  const { isLoading: isPortalSessionLoading, refetch: getPortalSession } =
    useSubscriptionPortalSessionQuery();

  const handleManageBillingClick = async () => {
    const { data } = await getPortalSession();

    if (data) {
      window.location.assign(data.url);
    }
  };

  if (isSubscriptionLoading)
    return (
      <Stack spacing={1}>
        <Skeleton
          animation="wave"
          variant="rectangular"
          width="100%"
          height={32}
        />
        <Skeleton
          animation="wave"
          variant="rectangular"
          width="100%"
          height={48}
        />
      </Stack>
    );

  const {
    latest_subscription: { plan_details, subscription_details },
    usage,
  } = subscriptionData;

  return (
    <Box>
      <Stack direction="row" sx={{ justifyContent: "space-between", mb: 1 }}>
        <Typography variant="h6">Subscription</Typography>
        {subscription_details && (
          <Button
            disabled={isPortalSessionLoading}
            size="small"
            onClick={handleManageBillingClick}
          >
            Manage billing
            {isPortalSessionLoading && <ButtonLoader />}
          </Button>
        )}
      </Stack>
      <Grid container spacing={2} sx={{ mb: 2 }}>
        <Grid item xs={12} sm={6} md={3}>
          <Descriptor heading="Current plan" details={plan_details.title} />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          {subscription_details && (
            <Descriptor
              heading="Status"
              details={capitalize(subscription_details.status)}
            />
          )}
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          {subscription_details && (
            <Descriptor
              heading="Start date"
              details={moment
                .unix(subscription_details.start_date)
                .format(TIME_FORMATS.DAY)}
            />
          )}
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          {subscription_details &&
            subscription_details.status === "trialing" && (
              <Descriptor
                heading="Trial ends"
                details={moment
                  .unix(subscription_details.trial_end)
                  .format(TIME_FORMATS.DAY)}
              />
            )}
        </Grid>
      </Grid>
      {subscription_details && (
        <Box>
          <Typography variant="h6" sx={{ mb: 1 }}>
            Usage
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4}>
              <Usage title="Notifications" usage={usage.notifications} />
            </Grid>
          </Grid>
        </Box>
      )}
      {!subscription_details && <SubscriptionPlans />}
    </Box>
  );
}
