import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Grid,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

import {
  useSubscriptionPlansQuery,
  useSubscriptionCheckoutQuery,
  useAccountQuery,
} from "lib/network";

import ButtonLoader from "components/ButtonLoader/ButtonLoader";

const planFeatures = (features) =>
  features.map((feature, idx) => {
    const { plan_flags, title } = feature;
    const enabled = !!plan_flags;
    const color = enabled ? "success.main" : "error.main";

    return (
      <Stack
        key={idx}
        direction="row"
        spacing={0.5}
        sx={{ color, alignItems: "center" }}
      >
        {enabled ? (
          <TaskAltIcon fontSize="sm" />
        ) : (
          <HighlightOffIcon fontSize="sm" />
        )}
        <Typography variant="body2">
          {title}
          {enabled && (
            <>
              {" "}
              (
              {plan_flags.amount === -1 ? (
                <strong>unlimited</strong>
              ) : (
                `${plan_flags.amount} ${feature.unit}`
              )}
              )
            </>
          )}
        </Typography>
      </Stack>
    );
  });

function Plan({ accountData, plan }) {
  const { isLoading, refetch: getCheckout } = useSubscriptionCheckoutQuery(
    plan.price_id
  );

  const handleChangeClick = async () => {
    const { data } = await getCheckout();

    if (data) {
      window.location.assign(data.url);
    }
  };

  const emailVerified = !!(accountData && accountData.email_verified);

  return (
    <Box
      component={Paper}
      sx={{
        height: "100%",
        borderRadius: 1,
        overflow: "hidden",
        position: "relative",
      }}
    >
      {plan.banner ? (
        <Box
          sx={{
            backgroundColor: "secondary.main",
            px: 2,
            py: 1,
            textAlign: "center",
          }}
        >
          <Typography fontWeight="fontWeightBold">
            {plan.banner.toUpperCase()}
          </Typography>
        </Box>
      ) : (
        <Box sx={{ height: { xs: 0, lg: 40 } }} />
      )}
      <Box sx={{ p: 2 }}>
        <Typography variant="h6">{plan.title}</Typography>
        <Typography sx={{ mb: 2 }}>
          {plan.price} {plan.priceDescription}
        </Typography>

        {/* -------------------- MOBILE LAYOUT -------------------- */}
        <Grid
          container
          spacing={1}
          sx={{ display: { xs: "flex", lg: "none" }, mb: 4 }}
        >
          {plan.features.map((_, idx) => (
            <Grid key={idx} item xs={12} sm={6}>
              {planFeatures([plan.features[idx]])}
            </Grid>
          ))}
        </Grid>

        {/* -------------------- DESKTOP LAYOUT -------------------- */}
        <Stack sx={{ display: { xs: "none", lg: "flex" }, mb: 4 }} spacing={1}>
          {planFeatures(plan.features)}
        </Stack>

        <Box>
          {plan.price_id ? (
            <Button
              disabled={isLoading || !emailVerified}
              variant="contained"
              onClick={handleChangeClick}
            >
              Select {plan.title}
              {isLoading && <ButtonLoader />}
            </Button>
          ) : (
            <Button disabled variant="outlined">
              Current plan
            </Button>
          )}
        </Box>
      </Box>
    </Box>
  );
}

export default function SubscriptionPlans() {
  const { data: plansData } = useSubscriptionPlansQuery();
  const { data: accountData } = useAccountQuery();

  const emailVerified = !!(accountData && accountData.email_verified);

  return (
    <Box>
      <Typography variant="h6" sx={{ mb: 1 }}>
        Available Plans
      </Typography>
      {!emailVerified && (
        <Alert severity="info" sx={{ mb: 1 }}>
          <AlertTitle>Email not verified</AlertTitle>
          You must verify your email before upgrading your subscription
        </Alert>
      )}
      {plansData && (
        <Grid container spacing={1}>
          {plansData.map((plan) => (
            <Grid item key={plan.id} xs={12} lg={4} sx={{}}>
              <Plan accountData={accountData} plan={plan} />
            </Grid>
          ))}
        </Grid>
      )}
    </Box>
  );
}
