import { useMutation, useQuery, useQueryClient } from "react-query";

import { ENDPOINTS, QUERIES, get, post } from "..";
import { useNotification } from "contexts/notifications";

export function useGetNotificationChannelsQuery(options = {}) {
  const { notify } = useNotification();

  return useQuery(
    QUERIES.NOTIFICATION.GET_CHANNELS,
    () => get(ENDPOINTS.NOTIFICATION.GET_CHANNELS),
    {
      onError(error) {
        notify(error.message);
      },
      ...options,
    }
  );
}

export function useSlackAuthMutation(options = {}) {
  const { notify } = useNotification();

  return useMutation((code) => post(ENDPOINTS.NOTIFICATION.SLACK_AUTH(code)), {
    onError(error) {
      notify(error.message);
    },
    ...options,
  });
}

export function useUnsubscribeServicesMutation(options = {}) {
  return useMutation(
    (token) => fetch(ENDPOINTS.NOTIFICATION.UNSUBSCRIBE(token)),
    {
      ...options,
    }
  );
}

export function useUnsubscribeAllMutation(options = {}) {
  return useMutation(
    (token) => fetch(ENDPOINTS.NOTIFICATION.UNSUBSCRIBE_ALL(token)),
    {
      ...options,
    }
  );
}

export function useSendTestSlackMessageMutation() {
  const { notify } = useNotification();

  return useMutation(
    (slack_channel_id) =>
      post(ENDPOINTS.NOTIFICATION.SEND_TEST_SLACK_MESSAGE, {
        body: { slack_channel_id },
      }),
    {
      onSuccess() {
        notify("Test message sent");
      },
      onError(error) {
        notify(error.message);
      },
    }
  );
}

export function useUpdateNotificationChannelMutation(options = {}) {
  const { notify } = useNotification();

  return useMutation(
    (body) =>
      post(ENDPOINTS.NOTIFICATION.UPDATE_CHANNEL, {
        body,
      }),
    {
      onError(error) {
        notify(error.message);
      },
      ...options,
    }
  );
}

export function useUpdateNotificationServicesMutation(options = {}) {
  const queryClient = useQueryClient();
  const { notify } = useNotification();

  return useMutation(
    (body) =>
      post(ENDPOINTS.NOTIFICATION.UPDATE_SERVICES, {
        body,
      }),
    {
      onSuccess() {
        queryClient.invalidateQueries(QUERIES.STATUS);
      },
      onError(error) {
        notify(error.message);
      },
      ...options,
    }
  );
}
