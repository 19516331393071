import React from "react";
import Paper from "@mui/material/Paper";
import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";

import { getStatus, isServiceDown } from "lib/status";

import StatusItem from "./StatusItem";
import { AlertTitle } from "@mui/material";
import ServicesButton from "components/ServicesButton/ServicesButton";

function StatusItems({ items }) {
  if (!items.length) return false;

  return (
    <Box component={Paper}>
      {items.map((item, idx) => {
        return (
          <StatusItem key={item.identifier} item={item} divider={idx > 0} />
        );
      })}
    </Box>
  );
}

export default function StatusList({ searchValue, statusState }) {
  const { items } = statusState;
  const mapped = items.map((s) => ({
    ...s,
    ...getStatus(s.status_source_type, s.status_indicator),
  }));
  const sorted = mapped.sort((a, b) => a.priority - b.priority);
  const downServices = sorted.filter((s) =>
    isServiceDown(s.status_source_type, s.status_indicator)
  );
  const upServices = sorted.filter(
    (s) => !isServiceDown(s.status_source_type, s.status_indicator)
  );

  if (!downServices.length && !upServices.length) {
    if (searchValue.length) {
      return (
        <Alert severity="info">
          No services found with the search terms. Try refining your search.
        </Alert>
      );
    }

    return (
      <Alert severity="info" sx={{ maxWidth: "md" }}>
        <AlertTitle>No services</AlertTitle>
        You don't have any services to track. Add some to get started.
        <Box sx={{ mt: 2 }}>
          <ServicesButton
            buttonProps={{
              variant: "outlined",
              size: "medium",
            }}
          />
        </Box>
      </Alert>
    );
  }

  return (
    <Box aria-label="status list">
      <StatusItems items={downServices} existMessage="Down Systems" />
      {!!(downServices.length && upServices.length) && (
        <Divider sx={{ my: 2, visibility: "hidden" }} />
      )}
      <StatusItems items={upServices} existMessage="Up Systems" />
    </Box>
  );
}
