import React, { useEffect, useState } from "react";
import {
  TextField,
  Checkbox,
  Box,
  InputAdornment,
  FormControlLabel,
  IconButton,
  Typography,
  Alert,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import { VariableSizeList } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";
import Fuse from "fuse.js";

import {
  getAvailableServices,
  getFilteredServices,
  TEXT_SEARCH_FILTER_ID,
} from ".";
import { useServicesData } from "contexts/services";
import { useStatusData } from "contexts/status";
import { merge } from "lodash";

const icon = <AddIcon />;
const checkedIcon = <RemoveCircleIcon />;
const fuseOptions = {
  threshold: 0.2,
  keys: ["identifier", "title"],
};

function renderRow(props) {
  const {
    data: { renderedServices, handleServicesChange, selectedServices },
    index,
    style,
  } = props;
  const dataSet = renderedServices[index];
  const checked = !!selectedServices.find(
    (s) => s.identifier === dataSet.identifier
  );
  const handleChange = (_, checked) => {
    handleServicesChange(dataSet, checked);
  };

  return (
    <FormControlLabel
      label={dataSet.title}
      control={
        <Checkbox
          icon={icon}
          checkedIcon={checkedIcon}
          onChange={handleChange}
          checked={checked}
        />
      }
      labelPlacement="start"
      style={style}
      sx={{
        width: "100%",
        px: 1,
        m: 0,
        userSelect: "none",
        height: 40,
        backgroundColor: checked ? "primary.50" : "auto",
        "&:hover": { backgroundColor: checked ? "auto" : "grey.50" },
        "& .MuiFormControlLabel-label": {
          flex: "1 1 auto",
        },
      }}
    />
  );
}

export default function ServicesList({
  filters,
  handleServicesChange,
  inputValue,
  selectedServices,
  setFilters,
  setInputValue,
}) {
  const [fuse, setFuse] = useState();
  const [availableServices, setAvailableServices] = useState([]);
  const [renderedServices, setRenderedServices] = useState([]);
  const { services } = useServicesData();
  const { status } = useStatusData();

  useEffect(() => {
    // remove services that are already added
    setAvailableServices(getAvailableServices(services, status));
  }, [services, status]);

  useEffect(() => {
    // apply filters to available services
    setRenderedServices(getFilteredServices(availableServices, filters));
  }, [availableServices, filters]);

  useEffect(() => {
    if (!fuse) {
      setFuse(new Fuse(availableServices, fuseOptions));
    } else {
      // NOTE: potential race condition
      fuse.setCollection(availableServices);
    }
  }, [fuse, availableServices]);

  if (!services.length) return false;

  const handleClearInput = () => {
    handleInputChange({ target: { value: "" } });
  };

  const handleInputChange = ({ target: { value } }) => {
    const newFilters = merge(filters, {
      values: {
        [TEXT_SEARCH_FILTER_ID]: { fuse, value },
      },
    });

    setFilters(newFilters);
    setInputValue(value);
  };

  return (
    <Box
      sx={{
        height: "100%",
        flex: "1 1 auto",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <TextField
        label="Search"
        fullWidth
        size="small"
        value={inputValue}
        onChange={handleInputChange}
        InputProps={{
          id: "service-search-input",
          endAdornment: (
            <InputAdornment position="end">
              {inputValue.length > 0 && (
                <IconButton
                  aria-label="clear search"
                  onClick={handleClearInput}
                  size="small"
                  sx={{ mr: 1 }}
                >
                  <ClearIcon />
                </IconButton>
              )}
              <SearchIcon />
            </InputAdornment>
          ),
        }}
        sx={{ mb: 1 }}
      />
      <Box>
        <Typography variant="subtitle2" fontWeight="bold" sx={{ mb: 1 }}>
          Results ({renderedServices.length})
        </Typography>
      </Box>
      <Box sx={{ flex: "1 1 auto" }}>
        <AutoSizer>
          {({ height, width }) => (
            <VariableSizeList
              height={height}
              width={width}
              itemSize={() => 42}
              itemCount={renderedServices.length}
              itemData={{
                renderedServices,
                handleServicesChange,
                selectedServices,
              }}
            >
              {renderRow}
            </VariableSizeList>
          )}
        </AutoSizer>
        {renderedServices.length === 0 && (
          <Alert severity="info">No results. Try refining your search.</Alert>
        )}
      </Box>
    </Box>
  );
}
