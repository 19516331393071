import { useQuery } from "react-query";

import { ENDPOINTS, QUERIES, get } from "..";
import { useNotification } from "contexts/notifications";

export function useSubscriptionQuery(options = {}) {
  const { notify } = useNotification();

  return useQuery(
    QUERIES.SUBSCRIPTION.GET,
    () => get(ENDPOINTS.SUBSCRIPTION.GET),
    {
      onError(error) {
        notify(error.message);
      },
      ...options,
    }
  );
}

export function useSubscriptionCheckoutQuery(priceId, options = {}) {
  const { notify } = useNotification();

  return useQuery(
    QUERIES.SUBSCRIPTION.CHECKOUT(priceId),
    () => get(ENDPOINTS.SUBSCRIPTION.CHECKOUT(priceId)),
    {
      enabled: false,
      retry: false,
      onError(error) {
        notify(error.message);
      },
      ...options,
    }
  );
}

export function useSubscriptionPlansQuery(options = {}) {
  const { notify } = useNotification();

  return useQuery(
    QUERIES.SUBSCRIPTION.PLANS,
    () => get(ENDPOINTS.SUBSCRIPTION.PLANS),
    {
      onError(error) {
        notify(error.message);
      },
      ...options,
    }
  );
}

export function useSubscriptionPortalSessionQuery(options = {}) {
  const { notify } = useNotification();

  return useQuery(
    QUERIES.SUBSCRIPTION.PORTAL_SESSION,
    () => get(ENDPOINTS.SUBSCRIPTION.PORTAL_SESSION),
    {
      enabled: false,
      retry: false,
      onError(error) {
        notify(error.message);
      },
      ...options,
    }
  );
}
