import config from "lib/config";

export const ENDPOINTS = Object.freeze({
  ACCOUNT: {
    GET: `${config.apiGatewayURL}/account`,
    SEND_VERIFICATION_LINK: `${config.apiGatewayURL}/account/send-verification-link`,
    VERIFY_EMAIL: (token) =>
      `${config.apiGatewayURL}/account/verify-email?token=${token}`,
  },
  DONATIONS: `${config.apiGatewayURL}/donations`,
  NOTIFICATION: {
    GET_CHANNELS: `${config.apiGatewayURL}/notifications/channels`,
    SEND_TEST_SLACK_MESSAGE: `${config.apiGatewayURL}/notifications/send-test-slack-message`,
    SLACK_AUTH: (code) =>
      `${config.apiGatewayURL}/notifications/slack-auth?code=${code}`,
    UNSUBSCRIBE: (token) =>
      `${config.apiGatewayURL}/notifications/unsubscribe?token=${token}`,
    UNSUBSCRIBE_ALL: (token) =>
      `${config.apiGatewayURL}/notifications/unsubscribe-all?token=${token}`,
    UPDATE_CHANNEL: `${config.apiGatewayURL}/notifications/channel`,
    UPDATE_SERVICES: `${config.apiGatewayURL}/notifications/services`,
  },
  SERVICE: (serviceId) => `${config.apiGatewayURL}/services/${serviceId}`,
  SERVICES: {
    DELETE: {
      ONE: `${config.apiGatewayURL}/services`,
    },
    GET: {
      ALL: `${config.apiGatewayURL}/services`,
    },
    POST: {
      USER: `${config.apiGatewayURL}/services/user`,
    },
  },
  STATUS: `${config.apiGatewayURL}/status`,
  SUBSCRIPTION: {
    CHECKOUT: (priceId) =>
      `${config.apiGatewayURL}/subscription/checkout?price_id=${priceId}`,
    GET: `${config.apiGatewayURL}/subscription`,
    PLANS: `${config.apiGatewayURL}/subscription/plans`,
    PORTAL_SESSION: `${config.apiGatewayURL}/subscription/portal-session`,
  },
});
