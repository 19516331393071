import React, { useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import { Alert, AlertTitle, Box } from "@mui/material";

import { AppContextProvider } from "contexts";
import { useAuth } from "contexts/auth";
import { ROUTES } from "lib/routes";

import PageTitle from "components/PageTitle/PageTitle";
import AppLayout from "containers/AppLayout/AppLayout";
import HomePage from "containers/HomePage/HomePage";
import NotFoundPage from "containers/NotFoundPage/NotFoundPage";
import SignupPage from "containers/SignupPage/SignupPage";

const queryClient = new QueryClient();

function UnavailablePage({ pageTitle, text }) {
  return (
    <Box>
      <PageTitle title={pageTitle} sx={{ mb: 2 }} />
      <Alert severity="info" sx={{ maxWidth: "md" }}>
        <AlertTitle>Not available</AlertTitle>
        {text}
      </Alert>
    </Box>
  );
}

export default function DemoApp() {
  const { setUser } = useAuth();

  useEffect(() => {
    setUser({
      attributes: {
        email: "demo-user@statusvista.com",
      },
    });
  }, []); // eslint-disable-line

  return (
    <QueryClientProvider client={queryClient}>
      <AppContextProvider>
        <AppLayout>
          <Routes>
            <Route
              path={ROUTES.ACCOUNT.path}
              element={
                <UnavailablePage
                  pageTitle="Account"
                  text="Account details are unavailable in the demo."
                />
              }
            />
            <Route path={ROUTES.HOME.path} element={<HomePage />} />
            <Route
              path={ROUTES.NOTIFICATIONS.path}
              element={
                <UnavailablePage
                  pageTitle="Notifications"
                  text="Notifications are unavailable in the demo. Integrate email and Slack notifications after creating an account."
                />
              }
            />
            <Route path={ROUTES.SIGNUP.path} element={<SignupPage />} />
            <Route path="*" element={<NotFoundPage />} />
          </Routes>
        </AppLayout>
      </AppContextProvider>
    </QueryClientProvider>
  );
}
