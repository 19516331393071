import { LinearProgress } from "@mui/material";

export default function ButtonLoader() {
  return (
    <LinearProgress
      variant="indeterminate"
      color="inherit"
      sx={{ width: "100%", position: "absolute", bottom: 0 }}
    />
  );
}
