import { useEffect } from "react";

import { getAboutUrl } from "lib/auth";

export default function Logout() {
  useEffect(() => {
    window.location.assign(getAboutUrl());
  }, []);

  return false;
}
