import React, { useEffect, useRef, useState } from "react";
import { Link, matchPath } from "react-router-dom";
import Box from "@mui/material/Box";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Paper from "@mui/material/Paper";
import { amber } from "@mui/material/colors";
import { visuallyHidden } from "@mui/utils";
import Typography from "@mui/material/Typography";
import LandscapeIcon from "@mui/icons-material/Landscape";
import DashboardIcon from "@mui/icons-material/Dashboard";
import PersonIcon from "@mui/icons-material/Person";
import NotificationsIcon from "@mui/icons-material/Notifications";
import {
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";

import { useIsDemo } from "lib/config";
import { ROUTES } from "lib/routes";

import Header from "components/Header/Header";

export default function AppLayout({ children }) {
  const [contentOffset, setContentOffset] = useState(0);
  const { isDemo } = useIsDemo();
  const demoAlertRef = useRef();

  useEffect(() => {
    if (!isDemo) return;

    const newOffset = () => demoAlertRef.current.getBoundingClientRect().height;
    setContentOffset(newOffset());

    const resizeListener = () => {
      if (demoAlertRef.current) {
        setContentOffset(newOffset());
      }
    };

    window.addEventListener("resize", resizeListener);

    return () => {
      window.removeEventListener("resize", resizeListener);
    };
  }, [demoAlertRef, isDemo]);

  return (
    <Box sx={{ height: "100%", display: "flex", flexDirection: "column" }}>
      {isDemo && (
        <Alert
          ref={demoAlertRef}
          severity="warning"
          elevation={1}
          sx={{
            position: "absolute",
            left: 0,
            right: 0,
            zIndex: "mobileStepper",
            "& .MuiAlert-message": { flex: "1 1 auto" },
          }}
        >
          <Box
            sx={{
              display: { md: "flex" },
              alignItems: { xs: "end", sm: "center" },
            }}
          >
            <Box sx={{ flex: "1 1 auto", mb: { xs: 1, md: 0 } }}>
              <AlertTitle>You are viewing a demo</AlertTitle>
              Some features have been disabled and the list of available
              services has been truncated.{" "}
              <Link to={ROUTES.SIGNUP.path}>
                <Typography
                  variant="body2"
                  sx={{
                    color: "primary.main",
                    display: "inline",
                    textDecoration: "underline",
                  }}
                >
                  Create an account
                </Typography>
              </Link>{" "}
              for the full experience.
            </Box>
            <Box sx={{ textAlign: { xs: "right" } }}>
              <Link to={ROUTES.SIGNUP.path}>
                <Button sx={{ ml: { md: 2 } }} variant="outlined">
                  Sign up now
                </Button>
              </Link>
            </Box>
          </Box>
        </Alert>
      )}
      <Stack
        direction="row"
        sx={{
          flex: "1 1 auto",
          height: "100%",
          pt: isDemo ? `${contentOffset}px` : 0,
        }}
      >
        {/* -------------------- DESKTOP SIDEBAR -------------------- */}
        <Stack
          data-testid="app-layout-sidebar"
          component={Paper}
          sx={{
            width: 250,
            minWidth: 250,
            overflow: "auto",
            display: { xs: "none", md: "flex" },
          }}
        >
          <Box sx={{ p: 2 }}>
            <Link to={ROUTES.HOME.path}>
              <Typography
                aria-label="status vista"
                component="div"
                sx={{
                  backgroundColor: amber[500],
                  color: "ButtonText",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  borderRadius: "3rem",
                  width: "3rem",
                  height: "3rem",
                  fontWeight: "bold",
                  userSelect: "none",
                }}
                title="StatusVista"
              >
                <Box sx={visuallyHidden}>Home</Box>
                <LandscapeIcon />
              </Typography>
            </Link>
          </Box>
          <Box sx={{ flex: "1 1 auto" }}>
            <List
              sx={{ height: "100%", display: "flex", flexDirection: "column" }}
            >
              <Link to={ROUTES.HOME.path}>
                <ListItem disablePadding>
                  <ListItemButton
                    selected={Boolean(
                      matchPath(ROUTES.HOME.path, window.location.pathname)
                    )}
                  >
                    <ListItemIcon>
                      <DashboardIcon />
                    </ListItemIcon>
                    <ListItemText primary="Dashboard" />
                  </ListItemButton>
                </ListItem>
              </Link>
              <Link to={ROUTES.NOTIFICATIONS.path}>
                <ListItem disablePadding>
                  <ListItemButton
                    selected={Boolean(
                      matchPath(
                        ROUTES.NOTIFICATIONS.path,
                        window.location.pathname
                      )
                    )}
                  >
                    <ListItemIcon>
                      <NotificationsIcon />
                    </ListItemIcon>
                    <ListItemText primary="Notifications" />
                  </ListItemButton>
                </ListItem>
              </Link>
              <Box sx={{ flex: "1 1 auto" }} />
              <Link to={ROUTES.ACCOUNT.path}>
                <ListItem disablePadding>
                  <ListItemButton
                    selected={Boolean(
                      matchPath(ROUTES.ACCOUNT.path, window.location.pathname)
                    )}
                  >
                    <ListItemIcon>
                      <PersonIcon />
                    </ListItemIcon>
                    <ListItemText primary="Account" />
                  </ListItemButton>
                </ListItem>
              </Link>
            </List>
          </Box>
        </Stack>
        <Box
          data-testid="app-layout-content"
          sx={{
            flex: "1 1 auto",
            display: "flex",
            flexDirection: "column",
            overflow: "hidden",
          }}
        >
          <Box sx={{ overflow: "auto", p: 2 }}>
            <Header />
            <Box sx={{ flex: "1 1 auto" }}>{children}</Box>
          </Box>
        </Box>
      </Stack>
    </Box>
  );
}
