import moment from "moment-timezone";
import { setupWorker, rest } from "msw";
import { faker } from "@faker-js/faker";

import { ENDPOINTS } from "./network";
import { isServiceDown, STATUS } from "./status";

const mockServices = [
  {
    identifier: "aws_apigateway-me-south-1",
    title: "Amazon API Gateway (Bahrain)",
    status_page_url: "https://health.aws.amazon.com/",
    status_source_type: "AWS_CURRENT_EVENTS_API",
    status_fetch_endpoint: "https://status.aws.amazon.com/currentevents.json",
  },
  {
    identifier: "aws_apigateway-af-south-1",
    title: "Amazon API Gateway (Cape Town)",
    status_page_url: "https://health.aws.amazon.com/",
    status_source_type: "AWS_CURRENT_EVENTS_API",
    status_fetch_endpoint: "https://status.aws.amazon.com/currentevents.json",
  },
  {
    identifier: "aws_apigateway-eu-central-1",
    title: "Amazon API Gateway (Frankfurt)",
    status_page_url: "https://health.aws.amazon.com/",
    status_source_type: "AWS_CURRENT_EVENTS_API",
    status_fetch_endpoint: "https://status.aws.amazon.com/currentevents.json",
  },
  {
    identifier: "aws_apigateway-ap-east-1",
    title: "Amazon API Gateway (Hong Kong)",
    status_page_url: "https://health.aws.amazon.com/",
    status_source_type: "AWS_CURRENT_EVENTS_API",
    status_fetch_endpoint: "https://status.aws.amazon.com/currentevents.json",
  },
  {
    identifier: "aws_apigateway-eu-west-1",
    title: "Amazon API Gateway (Ireland)",
    status_page_url: "https://health.aws.amazon.com/",
    status_source_type: "AWS_CURRENT_EVENTS_API",
    status_fetch_endpoint: "https://status.aws.amazon.com/currentevents.json",
  },
  {
    identifier: "aws_apigateway-ap-southeast-3",
    title: "Amazon API Gateway (Jakarta)",
    status_page_url: "https://health.aws.amazon.com/",
    status_source_type: "AWS_CURRENT_EVENTS_API",
    status_fetch_endpoint: "https://status.aws.amazon.com/currentevents.json",
  },
  {
    identifier: "aws_apigateway-eu-west-2",
    title: "Amazon API Gateway (London)",
    status_page_url: "https://health.aws.amazon.com/",
    status_source_type: "AWS_CURRENT_EVENTS_API",
    status_fetch_endpoint: "https://status.aws.amazon.com/currentevents.json",
  },
  {
    identifier: "aws_apigateway-eu-south-1",
    title: "Amazon API Gateway (Milan)",
    status_page_url: "https://health.aws.amazon.com/",
    status_source_type: "AWS_CURRENT_EVENTS_API",
    status_fetch_endpoint: "https://status.aws.amazon.com/currentevents.json",
  },
  {
    identifier: "aws_apigateway-ca-central-1",
    title: "Amazon API Gateway (Montreal)",
    status_page_url: "https://health.aws.amazon.com/",
    status_source_type: "AWS_CURRENT_EVENTS_API",
    status_fetch_endpoint: "https://status.aws.amazon.com/currentevents.json",
  },
  {
    identifier: "aws_apigateway-ap-south-1",
    title: "Amazon API Gateway (Mumbai)",
    status_page_url: "https://health.aws.amazon.com/",
    status_source_type: "AWS_CURRENT_EVENTS_API",
    status_fetch_endpoint: "https://status.aws.amazon.com/currentevents.json",
  },
  {
    identifier: "aws_apigateway-us-west-1",
    title: "Amazon API Gateway (N. California)",
    status_page_url: "https://health.aws.amazon.com/",
    status_source_type: "AWS_CURRENT_EVENTS_API",
    status_fetch_endpoint: "https://status.aws.amazon.com/currentevents.json",
  },
  {
    identifier: "aws_apigateway-us-east-1",
    title: "Amazon API Gateway (N. Virginia)",
    status_page_url: "https://health.aws.amazon.com/",
    status_source_type: "AWS_CURRENT_EVENTS_API",
    status_fetch_endpoint: "https://status.aws.amazon.com/currentevents.json",
  },
  {
    identifier: "aws_apigateway-us-east-2",
    title: "Amazon API Gateway (Ohio)",
    status_page_url: "https://health.aws.amazon.com/",
    status_source_type: "AWS_CURRENT_EVENTS_API",
    status_fetch_endpoint: "https://status.aws.amazon.com/currentevents.json",
  },
  {
    identifier: "aws_apigateway-us-west-2",
    title: "Amazon API Gateway (Oregon)",
    status_page_url: "https://health.aws.amazon.com/",
    status_source_type: "AWS_CURRENT_EVENTS_API",
    status_fetch_endpoint: "https://status.aws.amazon.com/currentevents.json",
  },
  {
    identifier: "aws_apigateway-ap-northeast-3",
    title: "Amazon API Gateway (Osaka)",
    status_page_url: "https://health.aws.amazon.com/",
    status_source_type: "AWS_CURRENT_EVENTS_API",
    status_fetch_endpoint: "https://status.aws.amazon.com/currentevents.json",
  },
  {
    identifier: "aws_apigateway-eu-west-3",
    title: "Amazon API Gateway (Paris)",
    status_page_url: "https://health.aws.amazon.com/",
    status_source_type: "AWS_CURRENT_EVENTS_API",
    status_fetch_endpoint: "https://status.aws.amazon.com/currentevents.json",
  },
  {
    identifier: "aws_apigateway-sa-east-1",
    title: "Amazon API Gateway (Sao Paulo)",
    status_page_url: "https://health.aws.amazon.com/",
    status_source_type: "AWS_CURRENT_EVENTS_API",
    status_fetch_endpoint: "https://status.aws.amazon.com/currentevents.json",
  },
  {
    identifier: "aws_apigateway-ap-northeast-2",
    title: "Amazon API Gateway (Seoul)",
    status_page_url: "https://health.aws.amazon.com/",
    status_source_type: "AWS_CURRENT_EVENTS_API",
    status_fetch_endpoint: "https://status.aws.amazon.com/currentevents.json",
  },
  {
    identifier: "aws_apigateway-ap-southeast-1",
    title: "Amazon API Gateway (Singapore)",
    status_page_url: "https://health.aws.amazon.com/",
    status_source_type: "AWS_CURRENT_EVENTS_API",
    status_fetch_endpoint: "https://status.aws.amazon.com/currentevents.json",
  },
  {
    identifier: "aws_apigateway-eu-north-1",
    title: "Amazon API Gateway (Stockholm)",
    status_page_url: "https://health.aws.amazon.com/",
    status_source_type: "AWS_CURRENT_EVENTS_API",
    status_fetch_endpoint: "https://status.aws.amazon.com/currentevents.json",
  },
  {
    identifier: "aws_apigateway-ap-southeast-2",
    title: "Amazon API Gateway (Sydney)",
    status_page_url: "https://health.aws.amazon.com/",
    status_source_type: "AWS_CURRENT_EVENTS_API",
    status_fetch_endpoint: "https://status.aws.amazon.com/currentevents.json",
  },
  {
    identifier: "aws_apigateway-ap-northeast-1",
    title: "Amazon API Gateway (Tokyo)",
    status_page_url: "https://health.aws.amazon.com/",
    status_source_type: "AWS_CURRENT_EVENTS_API",
    status_fetch_endpoint: "https://status.aws.amazon.com/currentevents.json",
  },
  {
    identifier: "aws_ec2-me-south-1",
    title: "Amazon Elastic Compute Cloud (Bahrain)",
    status_page_url: "https://health.aws.amazon.com/",
    status_source_type: "AWS_CURRENT_EVENTS_API",
    status_fetch_endpoint: "https://status.aws.amazon.com/currentevents.json",
  },
  {
    identifier: "aws_ec2-af-south-1",
    title: "Amazon Elastic Compute Cloud (Cape Town)",
    status_page_url: "https://health.aws.amazon.com/",
    status_source_type: "AWS_CURRENT_EVENTS_API",
    status_fetch_endpoint: "https://status.aws.amazon.com/currentevents.json",
  },
  {
    identifier: "aws_ec2-eu-central-1",
    title: "Amazon Elastic Compute Cloud (Frankfurt)",
    status_page_url: "https://health.aws.amazon.com/",
    status_source_type: "AWS_CURRENT_EVENTS_API",
    status_fetch_endpoint: "https://status.aws.amazon.com/currentevents.json",
  },
  {
    identifier: "aws_ec2-ap-east-1",
    title: "Amazon Elastic Compute Cloud (Hong Kong)",
    status_page_url: "https://health.aws.amazon.com/",
    status_source_type: "AWS_CURRENT_EVENTS_API",
    status_fetch_endpoint: "https://status.aws.amazon.com/currentevents.json",
  },
  {
    identifier: "aws_ec2-eu-west-1",
    title: "Amazon Elastic Compute Cloud (Ireland)",
    status_page_url: "https://health.aws.amazon.com/",
    status_source_type: "AWS_CURRENT_EVENTS_API",
    status_fetch_endpoint: "https://status.aws.amazon.com/currentevents.json",
  },
  {
    identifier: "aws_ec2-ap-southeast-3",
    title: "Amazon Elastic Compute Cloud (Jakarta)",
    status_page_url: "https://health.aws.amazon.com/",
    status_source_type: "AWS_CURRENT_EVENTS_API",
    status_fetch_endpoint: "https://status.aws.amazon.com/currentevents.json",
  },
  {
    identifier: "aws_ec2-eu-south-1",
    title: "Amazon Elastic Compute Cloud (Milan)",
    status_page_url: "https://health.aws.amazon.com/",
    status_source_type: "AWS_CURRENT_EVENTS_API",
    status_fetch_endpoint: "https://status.aws.amazon.com/currentevents.json",
  },
  {
    identifier: "aws_ec2-ca-central-1",
    title: "Amazon Elastic Compute Cloud (Montreal)",
    status_page_url: "https://health.aws.amazon.com/",
    status_source_type: "AWS_CURRENT_EVENTS_API",
    status_fetch_endpoint: "https://status.aws.amazon.com/currentevents.json",
  },
  {
    identifier: "aws_ec2-ap-south-1",
    title: "Amazon Elastic Compute Cloud (Mumbai)",
    status_page_url: "https://health.aws.amazon.com/",
    status_source_type: "AWS_CURRENT_EVENTS_API",
    status_fetch_endpoint: "https://status.aws.amazon.com/currentevents.json",
  },
  {
    identifier: "aws_ec2-us-west-1",
    title: "Amazon Elastic Compute Cloud (N. California)",
    status_page_url: "https://health.aws.amazon.com/",
    status_source_type: "AWS_CURRENT_EVENTS_API",
    status_fetch_endpoint: "https://status.aws.amazon.com/currentevents.json",
  },
  {
    identifier: "aws_ec2-us-east-1",
    title: "Amazon Elastic Compute Cloud (N. Virginia)",
    status_page_url: "https://health.aws.amazon.com/",
    status_source_type: "AWS_CURRENT_EVENTS_API",
    status_fetch_endpoint: "https://status.aws.amazon.com/currentevents.json",
  },
  {
    identifier: "aws_ec2-us-east-2",
    title: "Amazon Elastic Compute Cloud (Ohio)",
    status_page_url: "https://health.aws.amazon.com/",
    status_source_type: "AWS_CURRENT_EVENTS_API",
    status_fetch_endpoint: "https://status.aws.amazon.com/currentevents.json",
  },
  {
    identifier: "aws_ec2-us-west-2",
    title: "Amazon Elastic Compute Cloud (Oregon)",
    status_page_url: "https://health.aws.amazon.com/",
    status_source_type: "AWS_CURRENT_EVENTS_API",
    status_fetch_endpoint: "https://status.aws.amazon.com/currentevents.json",
  },
  {
    identifier: "aws_ec2-ap-northeast-3",
    title: "Amazon Elastic Compute Cloud (Osaka)",
    status_page_url: "https://health.aws.amazon.com/",
    status_source_type: "AWS_CURRENT_EVENTS_API",
    status_fetch_endpoint: "https://status.aws.amazon.com/currentevents.json",
  },
  {
    identifier: "aws_ec2-eu-west-3",
    title: "Amazon Elastic Compute Cloud (Paris)",
    status_page_url: "https://health.aws.amazon.com/",
    status_source_type: "AWS_CURRENT_EVENTS_API",
    status_fetch_endpoint: "https://status.aws.amazon.com/currentevents.json",
  },
  {
    identifier: "aws_ec2-sa-east-1",
    title: "Amazon Elastic Compute Cloud (Sao Paulo)",
    status_page_url: "https://health.aws.amazon.com/",
    status_source_type: "AWS_CURRENT_EVENTS_API",
    status_fetch_endpoint: "https://status.aws.amazon.com/currentevents.json",
  },
  {
    identifier: "aws_ec2-ap-northeast-2",
    title: "Amazon Elastic Compute Cloud (Seoul)",
    status_page_url: "https://health.aws.amazon.com/",
    status_source_type: "AWS_CURRENT_EVENTS_API",
    status_fetch_endpoint: "https://status.aws.amazon.com/currentevents.json",
  },
  {
    identifier: "aws_ec2-ap-southeast-1",
    title: "Amazon Elastic Compute Cloud (Singapore)",
    status_page_url: "https://health.aws.amazon.com/",
    status_source_type: "AWS_CURRENT_EVENTS_API",
    status_fetch_endpoint: "https://status.aws.amazon.com/currentevents.json",
  },
  {
    identifier: "aws_ec2-eu-north-1",
    title: "Amazon Elastic Compute Cloud (Stockholm)",
    status_page_url: "https://health.aws.amazon.com/",
    status_source_type: "AWS_CURRENT_EVENTS_API",
    status_fetch_endpoint: "https://status.aws.amazon.com/currentevents.json",
  },
  {
    identifier: "aws_ec2-ap-southeast-2",
    title: "Amazon Elastic Compute Cloud (Sydney)",
    status_page_url: "https://health.aws.amazon.com/",
    status_source_type: "AWS_CURRENT_EVENTS_API",
    status_fetch_endpoint: "https://status.aws.amazon.com/currentevents.json",
  },
  {
    identifier: "aws_ec2-ap-northeast-1",
    title: "Amazon Elastic Compute Cloud (Tokyo)",
    status_page_url: "https://health.aws.amazon.com/",
    status_source_type: "AWS_CURRENT_EVENTS_API",
    status_fetch_endpoint: "https://status.aws.amazon.com/currentevents.json",
  },
  {
    identifier: "atlassian",
    title: "Atlassian",
    status_page_url: "https://status.atlassian.com/",
    status_source_type: "STATUSPAGE_V2_API",
    status_fetch_endpoint: "https://status.atlassian.com/api/v2/status.json",
  },
  {
    identifier: "browserstack",
    title: "BrowserStack",
    status_page_url: "https://status.browserstack.com/",
    status_source_type: "STATUSPAGE_V2_API",
    status_fetch_endpoint: "https://status.browserstack.com/api/v2/status.json",
  },
  {
    identifier: "circleci",
    title: "CircleCI",
    status_page_url: "https://status.circleci.com/",
    status_source_type: "STATUSPAGE_V2_API",
    status_fetch_endpoint: "https://status.circleci.com/api/v2/status.json",
  },
  {
    identifier: "cloudinary",
    title: "Cloudinary",
    status_page_url: "https://status.cloudinary.com/",
    status_source_type: "STATUSPAGE_V2_API",
    status_fetch_endpoint: "https://status.cloudinary.com/api/v2/status.json",
  },
  {
    identifier: "digital_ocean",
    title: "DigitalOcean",
    status_page_url: "https://status.digitalocean.com/",
    status_source_type: "STATUSPAGE_V2_API",
    status_fetch_endpoint: "https://status.digitalocean.com/api/v2/status.json",
  },
  {
    identifier: "dropbox",
    title: "Dropbox",
    status_page_url: "https://status.dropbox.com/",
    status_source_type: "STATUSPAGE_V2_API",
    status_fetch_endpoint: "https://status.dropbox.com/api/v2/status.json",
  },
  {
    identifier: "elastic",
    title: "Elastic",
    status_page_url: "https://status.elastic.co/",
    status_source_type: "STATUSPAGE_V2_API",
    status_fetch_endpoint: "https://status.elastic.co/api/v2/status.json",
  },
  {
    identifier: "full_story",
    title: "FullStory",
    status_page_url: "https://status.fullstory.com/",
    status_source_type: "STATUSPAGE_V2_API",
    status_fetch_endpoint: "https://status.fullstory.com/api/v2/status.json",
  },
  {
    identifier: "github",
    title: "Github",
    status_page_url: "https://www.githubstatus.com/",
    status_source_type: "STATUSPAGE_V2_API",
    status_fetch_endpoint: "https://www.githubstatus.com/api/v2/status.json",
  },
  {
    identifier: "heap",
    title: "Heap",
    status_page_url: "https://status.heap.io/",
    status_source_type: "STATUSPAGE_V2_API",
    status_fetch_endpoint: "https://status.heap.io/api/v2/status.json",
  },
  {
    identifier: "intercom",
    title: "Intercom",
    status_page_url: "https://www.intercomstatus.com/",
    status_source_type: "STATUSPAGE_V2_API",
    status_fetch_endpoint: "https://www.intercomstatus.com/api/v2/status.json",
  },
  {
    identifier: "log_rocket",
    title: "LogRocket",
    status_page_url: "https://status.logrocket.com/",
    status_source_type: "STATUSPAGE_V2_API",
    status_fetch_endpoint: "https://status.logrocket.com/api/v2/status.json",
  },
  {
    identifier: "mailgun",
    title: "Mailgun",
    status_page_url: "https://status.mailgun.com/",
    status_source_type: "STATUSPAGE_V2_API",
    status_fetch_endpoint: "https://status.mailgun.com/api/v2/status.json",
  },
  {
    identifier: "new_relic",
    title: "New Relic",
    status_page_url: "https://status.newrelic.com/",
    status_source_type: "STATUSPAGE_V2_API",
    status_fetch_endpoint: "https://status.newrelic.com/api/v2/status.json",
  },
  {
    identifier: "papertrail",
    title: "Papertrail",
    status_page_url: "https://www.papertrailstatus.com/",
    status_source_type: "STATUSPAGE_V2_API",
    status_fetch_endpoint:
      "https://www.papertrailstatus.com/api/v2/status.json",
  },
  {
    identifier: "sentry",
    title: "Sentry",
    status_page_url: "https://status.sentry.io/",
    status_source_type: "STATUSPAGE_V2_API",
    status_fetch_endpoint: "https://status.sentry.io/api/v2/status.json",
  },
  {
    identifier: "travisci",
    title: "TravisCI",
    status_page_url: "https://www.traviscistatus.com/",
    status_source_type: "STATUSPAGE_V2_API",
    status_fetch_endpoint: "https://www.traviscistatus.com/api/v2/status.json",
  },
  {
    identifier: "twilio",
    title: "Twilio",
    status_page_url: "https://status.twilio.com/",
    status_source_type: "STATUSPAGE_V2_API",
    status_fetch_endpoint: "https://status.twilio.com/api/v2/status.json",
  },
  {
    identifier: "uploadcare",
    title: "Uploadcare",
    status_page_url: "https://status.uploadcare.com/",
    status_source_type: "STATUSPAGE_V2_API",
    status_fetch_endpoint: "https://status.uploadcare.com/api/v2/status.json",
  },
  {
    identifier: "zoom",
    title: "Zoom",
    status_page_url: "https://status.zoom.us/",
    status_source_type: "STATUSPAGE_V2_API",
    status_fetch_endpoint: "https://status.zoom.us/api/v2/status.json",
  },
];

const mockStatusUpdatedAt = moment().utc().format();
const mockStatus = [
  {
    identifier: "aws_apigateway-me-south-1",
    title: "Amazon API Gateway (Bahrain)",
    status_page_url: "https://health.aws.amazon.com/",
    status_source_type: "AWS_CURRENT_EVENTS_API",
    status_fetch_endpoint: "https://status.aws.amazon.com/currentevents.json",
    status_indicator: "resolved",
    status_description: "All Systems Operational",
    updated_at: mockStatusUpdatedAt,
  },
  {
    identifier: "aws_apigateway-af-south-1",
    title: "Amazon API Gateway (Cape Town)",
    status_page_url: "https://health.aws.amazon.com/",
    status_source_type: "AWS_CURRENT_EVENTS_API",
    status_fetch_endpoint: "https://status.aws.amazon.com/currentevents.json",
    status_indicator: "resolved",
    status_description: "All Systems Operational",
    updated_at: mockStatusUpdatedAt,
  },
  {
    identifier: "aws_apigateway-eu-central-1",
    title: "Amazon API Gateway (Frankfurt)",
    status_page_url: "https://health.aws.amazon.com/",
    status_source_type: "AWS_CURRENT_EVENTS_API",
    status_fetch_endpoint: "https://status.aws.amazon.com/currentevents.json",
    status_indicator: "resolved",
    status_description: "All Systems Operational",
    updated_at: mockStatusUpdatedAt,
  },
  {
    identifier: "aws_apigateway-ap-east-1",
    title: "Amazon API Gateway (Hong Kong)",
    status_page_url: "https://health.aws.amazon.com/",
    status_source_type: "AWS_CURRENT_EVENTS_API",
    status_fetch_endpoint: "https://status.aws.amazon.com/currentevents.json",
    status_indicator: "resolved",
    status_description: "All Systems Operational",
    updated_at: mockStatusUpdatedAt,
  },
  {
    identifier: "aws_apigateway-eu-west-1",
    title: "Amazon API Gateway (Ireland)",
    status_page_url: "https://health.aws.amazon.com/",
    status_source_type: "AWS_CURRENT_EVENTS_API",
    status_fetch_endpoint: "https://status.aws.amazon.com/currentevents.json",
    status_indicator: "resolved",
    status_description: "All Systems Operational",
    updated_at: mockStatusUpdatedAt,
  },
  {
    identifier: "aws_apigateway-ap-southeast-3",
    title: "Amazon API Gateway (Jakarta)",
    status_page_url: "https://health.aws.amazon.com/",
    status_source_type: "AWS_CURRENT_EVENTS_API",
    status_fetch_endpoint: "https://status.aws.amazon.com/currentevents.json",
    status_indicator: "resolved",
    status_description: "All Systems Operational",
    updated_at: mockStatusUpdatedAt,
  },
  {
    identifier: "aws_apigateway-eu-west-2",
    title: "Amazon API Gateway (London)",
    status_page_url: "https://health.aws.amazon.com/",
    status_source_type: "AWS_CURRENT_EVENTS_API",
    status_fetch_endpoint: "https://status.aws.amazon.com/currentevents.json",
    status_indicator: "resolved",
    status_description: "All Systems Operational",
    updated_at: mockStatusUpdatedAt,
  },
  {
    identifier: "aws_apigateway-eu-south-1",
    title: "Amazon API Gateway (Milan)",
    status_page_url: "https://health.aws.amazon.com/",
    status_source_type: "AWS_CURRENT_EVENTS_API",
    status_fetch_endpoint: "https://status.aws.amazon.com/currentevents.json",
    status_indicator: "resolved",
    status_description: "All Systems Operational",
    updated_at: mockStatusUpdatedAt,
  },
  {
    identifier: "aws_apigateway-ca-central-1",
    title: "Amazon API Gateway (Montreal)",
    status_page_url: "https://health.aws.amazon.com/",
    status_source_type: "AWS_CURRENT_EVENTS_API",
    status_fetch_endpoint: "https://status.aws.amazon.com/currentevents.json",
    status_indicator: "resolved",
    status_description: "All Systems Operational",
    updated_at: mockStatusUpdatedAt,
  },
  {
    identifier: "aws_apigateway-ap-south-1",
    title: "Amazon API Gateway (Mumbai)",
    status_page_url: "https://health.aws.amazon.com/",
    status_source_type: "AWS_CURRENT_EVENTS_API",
    status_fetch_endpoint: "https://status.aws.amazon.com/currentevents.json",
    status_indicator: "resolved",
    status_description: "All Systems Operational",
    updated_at: mockStatusUpdatedAt,
  },
  {
    identifier: "aws_apigateway-us-west-1",
    title: "Amazon API Gateway (N. California)",
    status_page_url: "https://health.aws.amazon.com/",
    status_source_type: "AWS_CURRENT_EVENTS_API",
    status_fetch_endpoint: "https://status.aws.amazon.com/currentevents.json",
    status_indicator: "resolved",
    status_description: "All Systems Operational",
    updated_at: mockStatusUpdatedAt,
  },
  {
    identifier: "aws_apigateway-us-east-1",
    title: "Amazon API Gateway (N. Virginia)",
    status_page_url: "https://health.aws.amazon.com/",
    status_source_type: "AWS_CURRENT_EVENTS_API",
    status_fetch_endpoint: "https://status.aws.amazon.com/currentevents.json",
    status_indicator: "resolved",
    status_description: "All Systems Operational",
    updated_at: mockStatusUpdatedAt,
  },
  {
    identifier: "aws_apigateway-us-east-2",
    title: "Amazon API Gateway (Ohio)",
    status_page_url: "https://health.aws.amazon.com/",
    status_source_type: "AWS_CURRENT_EVENTS_API",
    status_fetch_endpoint: "https://status.aws.amazon.com/currentevents.json",
    status_indicator: "resolved",
    status_description: "All Systems Operational",
    updated_at: mockStatusUpdatedAt,
  },
  {
    identifier: "aws_apigateway-us-west-2",
    title: "Amazon API Gateway (Oregon)",
    status_page_url: "https://health.aws.amazon.com/",
    status_source_type: "AWS_CURRENT_EVENTS_API",
    status_fetch_endpoint: "https://status.aws.amazon.com/currentevents.json",
    status_indicator: "resolved",
    status_description: "All Systems Operational",
    updated_at: mockStatusUpdatedAt,
  },
  {
    identifier: "aws_apigateway-ap-northeast-3",
    title: "Amazon API Gateway (Osaka)",
    status_page_url: "https://health.aws.amazon.com/",
    status_source_type: "AWS_CURRENT_EVENTS_API",
    status_fetch_endpoint: "https://status.aws.amazon.com/currentevents.json",
    status_indicator: "resolved",
    status_description: "All Systems Operational",
    updated_at: mockStatusUpdatedAt,
  },
  {
    identifier: "aws_apigateway-eu-west-3",
    title: "Amazon API Gateway (Paris)",
    status_page_url: "https://health.aws.amazon.com/",
    status_source_type: "AWS_CURRENT_EVENTS_API",
    status_fetch_endpoint: "https://status.aws.amazon.com/currentevents.json",
    status_indicator: "resolved",
    status_description: "All Systems Operational",
    updated_at: mockStatusUpdatedAt,
  },
  {
    identifier: "aws_apigateway-sa-east-1",
    title: "Amazon API Gateway (Sao Paulo)",
    status_page_url: "https://health.aws.amazon.com/",
    status_source_type: "AWS_CURRENT_EVENTS_API",
    status_fetch_endpoint: "https://status.aws.amazon.com/currentevents.json",
    status_indicator: "resolved",
    status_description: "All Systems Operational",
    updated_at: mockStatusUpdatedAt,
  },
  {
    identifier: "aws_apigateway-ap-northeast-2",
    title: "Amazon API Gateway (Seoul)",
    status_page_url: "https://health.aws.amazon.com/",
    status_source_type: "AWS_CURRENT_EVENTS_API",
    status_fetch_endpoint: "https://status.aws.amazon.com/currentevents.json",
    status_indicator: "resolved",
    status_description: "All Systems Operational",
    updated_at: mockStatusUpdatedAt,
  },
  {
    identifier: "aws_apigateway-ap-southeast-1",
    title: "Amazon API Gateway (Singapore)",
    status_page_url: "https://health.aws.amazon.com/",
    status_source_type: "AWS_CURRENT_EVENTS_API",
    status_fetch_endpoint: "https://status.aws.amazon.com/currentevents.json",
    status_indicator: "resolved",
    status_description: "All Systems Operational",
    updated_at: mockStatusUpdatedAt,
  },
  {
    identifier: "aws_apigateway-eu-north-1",
    title: "Amazon API Gateway (Stockholm)",
    status_page_url: "https://health.aws.amazon.com/",
    status_source_type: "AWS_CURRENT_EVENTS_API",
    status_fetch_endpoint: "https://status.aws.amazon.com/currentevents.json",
    status_indicator: "resolved",
    status_description: "All Systems Operational",
    updated_at: mockStatusUpdatedAt,
  },
  {
    identifier: "aws_apigateway-ap-southeast-2",
    title: "Amazon API Gateway (Sydney)",
    status_page_url: "https://health.aws.amazon.com/",
    status_source_type: "AWS_CURRENT_EVENTS_API",
    status_fetch_endpoint: "https://status.aws.amazon.com/currentevents.json",
    status_indicator: "resolved",
    status_description: "All Systems Operational",
    updated_at: mockStatusUpdatedAt,
  },
  {
    identifier: "aws_apigateway-ap-northeast-1",
    title: "Amazon API Gateway (Tokyo)",
    status_page_url: "https://health.aws.amazon.com/",
    status_source_type: "AWS_CURRENT_EVENTS_API",
    status_fetch_endpoint: "https://status.aws.amazon.com/currentevents.json",
    status_indicator: "resolved",
    status_description: "All Systems Operational",
    updated_at: mockStatusUpdatedAt,
  },
  {
    identifier: "aws_ec2-me-south-1",
    title: "Amazon Elastic Compute Cloud (Bahrain)",
    status_page_url: "https://health.aws.amazon.com/",
    status_source_type: "AWS_CURRENT_EVENTS_API",
    status_fetch_endpoint: "https://status.aws.amazon.com/currentevents.json",
    status_indicator: "resolved",
    status_description: "All Systems Operational",
    updated_at: mockStatusUpdatedAt,
  },
  {
    identifier: "aws_ec2-af-south-1",
    title: "Amazon Elastic Compute Cloud (Cape Town)",
    status_page_url: "https://health.aws.amazon.com/",
    status_source_type: "AWS_CURRENT_EVENTS_API",
    status_fetch_endpoint: "https://status.aws.amazon.com/currentevents.json",
    status_indicator: "resolved",
    status_description: "All Systems Operational",
    updated_at: mockStatusUpdatedAt,
  },
  {
    identifier: "aws_ec2-eu-central-1",
    title: "Amazon Elastic Compute Cloud (Frankfurt)",
    status_page_url: "https://health.aws.amazon.com/",
    status_source_type: "AWS_CURRENT_EVENTS_API",
    status_fetch_endpoint: "https://status.aws.amazon.com/currentevents.json",
    status_indicator: "resolved",
    status_description: "All Systems Operational",
    updated_at: mockStatusUpdatedAt,
  },
  {
    identifier: "aws_ec2-ap-east-1",
    title: "Amazon Elastic Compute Cloud (Hong Kong)",
    status_page_url: "https://health.aws.amazon.com/",
    status_source_type: "AWS_CURRENT_EVENTS_API",
    status_fetch_endpoint: "https://status.aws.amazon.com/currentevents.json",
    status_indicator: "resolved",
    status_description: "All Systems Operational",
    updated_at: mockStatusUpdatedAt,
  },
  {
    identifier: "aws_ec2-eu-west-1",
    title: "Amazon Elastic Compute Cloud (Ireland)",
    status_page_url: "https://health.aws.amazon.com/",
    status_source_type: "AWS_CURRENT_EVENTS_API",
    status_fetch_endpoint: "https://status.aws.amazon.com/currentevents.json",
    status_indicator: "resolved",
    status_description: "All Systems Operational",
    updated_at: mockStatusUpdatedAt,
  },
  {
    identifier: "aws_ec2-ap-southeast-3",
    title: "Amazon Elastic Compute Cloud (Jakarta)",
    status_page_url: "https://health.aws.amazon.com/",
    status_source_type: "AWS_CURRENT_EVENTS_API",
    status_fetch_endpoint: "https://status.aws.amazon.com/currentevents.json",
    status_indicator: "resolved",
    status_description: "All Systems Operational",
    updated_at: mockStatusUpdatedAt,
  },
  {
    identifier: "aws_ec2-eu-south-1",
    title: "Amazon Elastic Compute Cloud (Milan)",
    status_page_url: "https://health.aws.amazon.com/",
    status_source_type: "AWS_CURRENT_EVENTS_API",
    status_fetch_endpoint: "https://status.aws.amazon.com/currentevents.json",
    status_indicator: "resolved",
    status_description: "All Systems Operational",
    updated_at: mockStatusUpdatedAt,
  },
  {
    identifier: "aws_ec2-ca-central-1",
    title: "Amazon Elastic Compute Cloud (Montreal)",
    status_page_url: "https://health.aws.amazon.com/",
    status_source_type: "AWS_CURRENT_EVENTS_API",
    status_fetch_endpoint: "https://status.aws.amazon.com/currentevents.json",
    status_indicator: "resolved",
    status_description: "All Systems Operational",
    updated_at: mockStatusUpdatedAt,
  },
  {
    identifier: "aws_ec2-ap-south-1",
    title: "Amazon Elastic Compute Cloud (Mumbai)",
    status_page_url: "https://health.aws.amazon.com/",
    status_source_type: "AWS_CURRENT_EVENTS_API",
    status_fetch_endpoint: "https://status.aws.amazon.com/currentevents.json",
    status_indicator: "resolved",
    status_description: "All Systems Operational",
    updated_at: mockStatusUpdatedAt,
  },
  {
    identifier: "aws_ec2-us-west-1",
    title: "Amazon Elastic Compute Cloud (N. California)",
    status_page_url: "https://health.aws.amazon.com/",
    status_source_type: "AWS_CURRENT_EVENTS_API",
    status_fetch_endpoint: "https://status.aws.amazon.com/currentevents.json",
    status_indicator: "resolved",
    status_description: "All Systems Operational",
    updated_at: mockStatusUpdatedAt,
  },
  {
    identifier: "aws_ec2-us-east-1",
    title: "Amazon Elastic Compute Cloud (N. Virginia)",
    status_page_url: "https://health.aws.amazon.com/",
    status_source_type: "AWS_CURRENT_EVENTS_API",
    status_fetch_endpoint: "https://status.aws.amazon.com/currentevents.json",
    status_indicator: "resolved",
    status_description: "All Systems Operational",
    updated_at: mockStatusUpdatedAt,
  },
  {
    identifier: "aws_ec2-us-east-2",
    title: "Amazon Elastic Compute Cloud (Ohio)",
    status_page_url: "https://health.aws.amazon.com/",
    status_source_type: "AWS_CURRENT_EVENTS_API",
    status_fetch_endpoint: "https://status.aws.amazon.com/currentevents.json",
    status_indicator: "resolved",
    status_description: "All Systems Operational",
    updated_at: mockStatusUpdatedAt,
  },
  {
    identifier: "aws_ec2-us-west-2",
    title: "Amazon Elastic Compute Cloud (Oregon)",
    status_page_url: "https://health.aws.amazon.com/",
    status_source_type: "AWS_CURRENT_EVENTS_API",
    status_fetch_endpoint: "https://status.aws.amazon.com/currentevents.json",
    status_indicator: "resolved",
    status_description: "All Systems Operational",
    updated_at: mockStatusUpdatedAt,
  },
  {
    identifier: "aws_ec2-ap-northeast-3",
    title: "Amazon Elastic Compute Cloud (Osaka)",
    status_page_url: "https://health.aws.amazon.com/",
    status_source_type: "AWS_CURRENT_EVENTS_API",
    status_fetch_endpoint: "https://status.aws.amazon.com/currentevents.json",
    status_indicator: "resolved",
    status_description: "All Systems Operational",
    updated_at: mockStatusUpdatedAt,
  },
  {
    identifier: "aws_ec2-eu-west-3",
    title: "Amazon Elastic Compute Cloud (Paris)",
    status_page_url: "https://health.aws.amazon.com/",
    status_source_type: "AWS_CURRENT_EVENTS_API",
    status_fetch_endpoint: "https://status.aws.amazon.com/currentevents.json",
    status_indicator: "resolved",
    status_description: "All Systems Operational",
    updated_at: mockStatusUpdatedAt,
  },
  {
    identifier: "aws_ec2-sa-east-1",
    title: "Amazon Elastic Compute Cloud (Sao Paulo)",
    status_page_url: "https://health.aws.amazon.com/",
    status_source_type: "AWS_CURRENT_EVENTS_API",
    status_fetch_endpoint: "https://status.aws.amazon.com/currentevents.json",
    status_indicator: "resolved",
    status_description: "All Systems Operational",
    updated_at: mockStatusUpdatedAt,
  },
  {
    identifier: "aws_ec2-ap-northeast-2",
    title: "Amazon Elastic Compute Cloud (Seoul)",
    status_page_url: "https://health.aws.amazon.com/",
    status_source_type: "AWS_CURRENT_EVENTS_API",
    status_fetch_endpoint: "https://status.aws.amazon.com/currentevents.json",
    status_indicator: "resolved",
    status_description: "All Systems Operational",
    updated_at: mockStatusUpdatedAt,
  },
  {
    identifier: "aws_ec2-ap-southeast-1",
    title: "Amazon Elastic Compute Cloud (Singapore)",
    status_page_url: "https://health.aws.amazon.com/",
    status_source_type: "AWS_CURRENT_EVENTS_API",
    status_fetch_endpoint: "https://status.aws.amazon.com/currentevents.json",
    status_indicator: "resolved",
    status_description: "All Systems Operational",
    updated_at: mockStatusUpdatedAt,
  },
  {
    identifier: "aws_ec2-eu-north-1",
    title: "Amazon Elastic Compute Cloud (Stockholm)",
    status_page_url: "https://health.aws.amazon.com/",
    status_source_type: "AWS_CURRENT_EVENTS_API",
    status_fetch_endpoint: "https://status.aws.amazon.com/currentevents.json",
    status_indicator: "resolved",
    status_description: "All Systems Operational",
    updated_at: mockStatusUpdatedAt,
  },
  {
    identifier: "aws_ec2-ap-southeast-2",
    title: "Amazon Elastic Compute Cloud (Sydney)",
    status_page_url: "https://health.aws.amazon.com/",
    status_source_type: "AWS_CURRENT_EVENTS_API",
    status_fetch_endpoint: "https://status.aws.amazon.com/currentevents.json",
    status_indicator: "resolved",
    status_description: "All Systems Operational",
    updated_at: mockStatusUpdatedAt,
  },
  {
    identifier: "aws_ec2-ap-northeast-1",
    title: "Amazon Elastic Compute Cloud (Tokyo)",
    status_page_url: "https://health.aws.amazon.com/",
    status_source_type: "AWS_CURRENT_EVENTS_API",
    status_fetch_endpoint: "https://status.aws.amazon.com/currentevents.json",
    status_indicator: "resolved",
    status_description: "All Systems Operational",
    updated_at: mockStatusUpdatedAt,
  },
  {
    identifier: "atlassian",
    title: "Atlassian",
    status_page_url: "https://status.atlassian.com/",
    status_source_type: "STATUSPAGE_V2_API",
    status_fetch_endpoint: "https://status.atlassian.com/api/v2/status.json",
    status_indicator: "none",
    status_description: "All Systems Operational",
    updated_at: mockStatusUpdatedAt,
  },
  {
    identifier: "browserstack",
    title: "BrowserStack",
    status_page_url: "https://status.browserstack.com/",
    status_source_type: "STATUSPAGE_V2_API",
    status_fetch_endpoint: "https://status.browserstack.com/api/v2/status.json",
    status_indicator: "none",
    status_description: "All Systems Operational",
    updated_at: mockStatusUpdatedAt,
  },
  {
    identifier: "circleci",
    title: "CircleCI",
    status_page_url: "https://status.circleci.com/",
    status_source_type: "STATUSPAGE_V2_API",
    status_fetch_endpoint: "https://status.circleci.com/api/v2/status.json",
    status_indicator: "none",
    status_description: "All Systems Operational",
    updated_at: mockStatusUpdatedAt,
  },
  {
    identifier: "cloudinary",
    title: "Cloudinary",
    status_page_url: "https://status.cloudinary.com/",
    status_source_type: "STATUSPAGE_V2_API",
    status_fetch_endpoint: "https://status.cloudinary.com/api/v2/status.json",
    status_indicator: "none",
    status_description: "All Systems Operational",
    updated_at: mockStatusUpdatedAt,
  },
  {
    identifier: "digital_ocean",
    title: "DigitalOcean",
    status_page_url: "https://status.digitalocean.com/",
    status_source_type: "STATUSPAGE_V2_API",
    status_fetch_endpoint: "https://status.digitalocean.com/api/v2/status.json",
    status_indicator: "none",
    status_description: "All Systems Operational",
    updated_at: mockStatusUpdatedAt,
  },
  {
    identifier: "dropbox",
    title: "Dropbox",
    status_page_url: "https://status.dropbox.com/",
    status_source_type: "STATUSPAGE_V2_API",
    status_fetch_endpoint: "https://status.dropbox.com/api/v2/status.json",
    status_indicator: "none",
    status_description: "All Systems Operational",
    updated_at: mockStatusUpdatedAt,
  },
  {
    identifier: "elastic",
    title: "Elastic",
    status_page_url: "https://status.elastic.co/",
    status_source_type: "STATUSPAGE_V2_API",
    status_fetch_endpoint: "https://status.elastic.co/api/v2/status.json",
    status_indicator: "minor",
    status_description: "Partially Degraded Service",
    updated_at: mockStatusUpdatedAt,
  },
  {
    identifier: "full_story",
    title: "FullStory",
    status_page_url: "https://status.fullstory.com/",
    status_source_type: "STATUSPAGE_V2_API",
    status_fetch_endpoint: "https://status.fullstory.com/api/v2/status.json",
    status_indicator: "none",
    status_description: "All Systems Operational",
    updated_at: mockStatusUpdatedAt,
  },
  {
    identifier: "github",
    title: "Github",
    status_page_url: "https://www.githubstatus.com/",
    status_source_type: "STATUSPAGE_V2_API",
    status_fetch_endpoint: "https://www.githubstatus.com/api/v2/status.json",
    status_indicator: "none",
    status_description: "All Systems Operational",
    updated_at: mockStatusUpdatedAt,
  },
  {
    identifier: "heap",
    title: "Heap",
    status_page_url: "https://status.heap.io/",
    status_source_type: "STATUSPAGE_V2_API",
    status_fetch_endpoint: "https://status.heap.io/api/v2/status.json",
    status_indicator: "none",
    status_description: "All Systems Operational",
    updated_at: mockStatusUpdatedAt,
  },
  {
    identifier: "travisci",
    title: "TravisCI",
    status_page_url: "https://www.traviscistatus.com/",
    status_source_type: "STATUSPAGE_V2_API",
    status_fetch_endpoint: "https://www.traviscistatus.com/api/v2/status.json",
    status_indicator: "none",
    status_description: "All Systems Operational",
    updated_at: mockStatusUpdatedAt,
  },
  {
    identifier: "twilio",
    title: "Twilio",
    status_page_url: "https://status.twilio.com/",
    status_source_type: "STATUSPAGE_V2_API",
    status_fetch_endpoint: "https://status.twilio.com/api/v2/status.json",
    status_indicator: "minor",
    status_description: "Partially Degraded Service",
    updated_at: mockStatusUpdatedAt,
  },
  {
    identifier: "uploadcare",
    title: "Uploadcare",
    status_page_url: "https://status.uploadcare.com/",
    status_source_type: "STATUSPAGE_V2_API",
    status_fetch_endpoint: "https://status.uploadcare.com/api/v2/status.json",
    status_indicator: "none",
    status_description: "All Systems Operational",
    updated_at: mockStatusUpdatedAt,
  },
  {
    identifier: "zoom",
    title: "Zoom",
    status_page_url: "https://status.zoom.us/",
    status_source_type: "STATUSPAGE_V2_API",
    status_fetch_endpoint: "https://status.zoom.us/api/v2/status.json",
    status_indicator: "none",
    status_description: "All Systems Operational",
    updated_at: mockStatusUpdatedAt,
  },
  {
    identifier: "intercom",
    title: "Intercom",
    status_page_url: "https://www.intercomstatus.com/",
    status_source_type: "STATUSPAGE_V2_API",
    status_fetch_endpoint: "https://www.intercomstatus.com/api/v2/status.json",
    status_indicator: "none",
    status_description: "All Systems Operational",
    updated_at: mockStatusUpdatedAt,
  },
  {
    identifier: "log_rocket",
    title: "LogRocket",
    status_page_url: "https://status.logrocket.com/",
    status_source_type: "STATUSPAGE_V2_API",
    status_fetch_endpoint: "https://status.logrocket.com/api/v2/status.json",
    status_indicator: "none",
    status_description: "All Systems Operational",
    updated_at: mockStatusUpdatedAt,
  },
  {
    identifier: "mailgun",
    title: "Mailgun",
    status_page_url: "https://status.mailgun.com/",
    status_source_type: "STATUSPAGE_V2_API",
    status_fetch_endpoint: "https://status.mailgun.com/api/v2/status.json",
    status_indicator: "none",
    status_description: "All Systems Operational",
    updated_at: mockStatusUpdatedAt,
  },
  {
    identifier: "new_relic",
    title: "New Relic",
    status_page_url: "https://status.newrelic.com/",
    status_source_type: "STATUSPAGE_V2_API",
    status_fetch_endpoint: "https://status.newrelic.com/api/v2/status.json",
    status_indicator: "none",
    status_description: "All Systems Operational",
    updated_at: mockStatusUpdatedAt,
  },
  {
    identifier: "papertrail",
    title: "Papertrail",
    status_page_url: "https://www.papertrailstatus.com/",
    status_source_type: "STATUSPAGE_V2_API",
    status_fetch_endpoint:
      "https://www.papertrailstatus.com/api/v2/status.json",
    status_indicator: "none",
    status_description: "All Systems Operational",
    updated_at: mockStatusUpdatedAt,
  },
  {
    identifier: "sentry",
    title: "Sentry",
    status_page_url: "https://status.sentry.io/",
    status_source_type: "STATUSPAGE_V2_API",
    status_fetch_endpoint: "https://status.sentry.io/api/v2/status.json",
    status_indicator: "none",
    status_description: "All Systems Operational",
    updated_at: mockStatusUpdatedAt,
  },
];

const defaultStatusDescriptions = {
  critical: "Total System Outage",
  major: "Major System Outage",
  minor: "Partially Degraded Service",
  maintenance: "System Under Maintenance",
  unknown: "System Status Unknown",
  none: "All Systems Operational",
};

// copied from api utils
function calculateUptime(
  statusSourceType,
  startTime,
  endTime,
  statusChanges,
  currentUpdatedAt
) {
  const startTimeUnix = moment(startTime).unix();
  const endTimeUnix = moment(endTime).unix();
  const currentUpdatedAtUnix = moment(currentUpdatedAt).unix();
  let uptimePercentage = 100;

  if (statusChanges.length) {
    const firstKnown = statusChanges[0];
    const firstKnownStartTime = moment(firstKnown.created_at).unix();
    const beginUnix =
      firstKnownStartTime < startTimeUnix ? startTimeUnix : firstKnownStartTime;
    const uptimePotential = endTimeUnix - beginUnix;
    let totalUptime = uptimePotential;

    for (let i = 0; i < statusChanges.length; i++) {
      const current = statusChanges[i];
      const next = statusChanges[i + 1];
      const currentUnix = moment(current.created_at).unix();

      if (isServiceDown(statusSourceType, current.status_indicator)) {
        if (next) {
          // next change exists
          const nextUnix = moment(next.created_at).unix();

          if (i === 0) {
            // first record before beginUnix, calculate using beginUnix
            totalUptime -= nextUnix - beginUnix;
          } else {
            // after beginUnix, use entire difference
            totalUptime -= nextUnix - currentUnix;
          }
        } else {
          // no other changes exist
          if (i === 0) {
            // first and only item in the list
            totalUptime -= endTimeUnix - beginUnix;
          } else {
            totalUptime -= currentUpdatedAtUnix - currentUnix;
          }
        }
      }
    }

    uptimePercentage = (totalUptime / uptimePotential) * 100;
  }

  return Math.round(uptimePercentage * 100) / 100;
}

function buildServiceResponse(serviceId) {
  const service = mockServices.find((s) => s.identifier === serviceId);
  const { status_source_type } = service;
  const defaultStatus =
    status_source_type === "STATUSPAGE_V2_API" ? "none" : "resolved";
  const now = moment();
  const updatedAt = now.clone().subtract(5, "minutes").utc().format();
  const startTime = now.clone().subtract(60, "days").utc().format();
  const times = [];

  for (let i = 0; i < 10; i++) {
    const dates = faker.date
      .betweens(startTime, updatedAt)
      .map((d) => moment(d));
    times.push(...dates);
  }

  const sorted = times
    .sort((a, b) => a.unix() - b.unix())
    .map((t) => t.utc().format());
  const statusChanges = sorted.map((time, idx) => {
    const statusType = STATUS[status_source_type];
    const max = Object.keys(statusType).length - 1;
    const min = 0;
    const random = Math.floor(Math.random() * (max - min + 1) + min);
    const randomStatus = statusType[Object.keys(statusType)[random]];
    const randomId = randomStatus.id;
    const randomDescription = defaultStatusDescriptions[randomId];
    const defaultNone = Math.floor(Math.random() * 3);
    const isNone = idx % 2 === 0 || defaultNone === 0;

    return {
      created_at: time,
      status_indicator: isNone ? defaultStatus : randomId,
      status_description: randomDescription,
    };
  });

  const unique = statusChanges.filter((s, idx) => {
    try {
      const previous = statusChanges[idx - 1];
      if (!previous) {
        return true;
      }

      if (
        s.status_indicator === defaultStatus &&
        previous.status_indicator === defaultStatus
      ) {
        return false;
      }

      return true;
    } catch (e) {
      return false;
    }
  });
  const uptime = calculateUptime(
    status_source_type,
    startTime,
    now,
    unique,
    now
  );

  return {
    ...service,
    updated_at: now,
    uptime_percentage: uptime,
    status_changes: {
      start_time: startTime,
      items: unique,
    },
  };
}

const constructedDetails = {};

const defaultStatus = [
  "atlassian",
  "browserstack",
  "circleci",
  "intercom",
  "github",
  "travisci",
  "twilio",
  "zoom",
].map((s) => mockStatus.find((m) => m.identifier === s));

function getUniqueStatus(items) {
  return items
    .reduce((acc, val) => {
      const existing = acc.find((s) => s.identifier === val.identifier);

      if (existing) return acc;

      return [...acc, val];
    }, [])
    .sort((a, b) => a.title.localeCompare(b.title));
}

const worker = setupWorker(
  rest.get(ENDPOINTS.STATUS, (req, res, ctx) => {
    const storageItem = localStorage.getItem("sv-storage");
    const storageStatus = storageItem ? JSON.parse(storageItem).status : [];
    const merged = [
      ...(storageItem ? storageStatus : defaultStatus),
      ...storageStatus,
    ];
    const unique = getUniqueStatus(merged);

    localStorage.setItem("sv-storage", JSON.stringify({ status: unique }));

    return res(ctx.json(unique));
  }),
  rest.get(ENDPOINTS.SERVICES.GET.ALL, (req, res, ctx) => {
    return res(ctx.json(mockServices));
  }),
  rest.get(`${ENDPOINTS.SERVICES.GET.ALL}/*`, (req, res, ctx) => {
    const serviceId = req.params[0];
    const serviceData = constructedDetails[serviceId];

    return res(ctx.json(serviceData));
  }),
  rest.post(ENDPOINTS.SERVICES.POST.USER, (req, res, ctx) => {
    const {
      body: { services },
    } = req;
    const storageItem = localStorage.getItem("sv-storage");
    const storageStatus = storageItem ? JSON.parse(storageItem).status : [];
    const added = services.map((s) =>
      mockStatus.find((m) => m.identifier === s)
    );
    const merged = [...added, ...storageStatus];
    const unique = getUniqueStatus(merged);

    localStorage.setItem("sv-storage", JSON.stringify({ status: unique }));

    return res(ctx.json({ message: "OK" }));
  }),
  rest.delete(ENDPOINTS.SERVICES.DELETE.ONE, (req, res, ctx) => {
    const {
      body: { service_id },
    } = req;
    const storageItem = localStorage.getItem("sv-storage");
    const storageStatus = storageItem ? JSON.parse(storageItem).status : [];
    const statusIndex = storageStatus.findIndex(
      (s) => s.identifier === service_id
    );

    if (statusIndex > -1) {
      storageStatus.splice(statusIndex, 1);
    }
    const unique = getUniqueStatus(storageStatus);

    localStorage.setItem("sv-storage", JSON.stringify({ status: unique }));

    return res(ctx.json({ message: "OK" }));
  })
);

export function initMocks() {
  mockServices.forEach(({ identifier }) => {
    const serviceResponse = buildServiceResponse(identifier);

    constructedDetails[identifier] = serviceResponse;
  });

  worker.start();
}
