import { createContext, useContext, useState } from "react";
import { useQuery } from "react-query";

import { useNotification } from "./notifications";
import { ENDPOINTS, QUERIES, get } from "lib/network";

import PageLoader from "components/Loader/PageLoader";

const StatusDataContext = createContext();

export function useStatusData() {
  return useContext(StatusDataContext);
}

export function StatusDataProvider({ children }) {
  const [statusData, setStatusData] = useState([]);
  const { notify } = useNotification();
  const { isFetching, isLoading, error } = useQuery(
    QUERIES.STATUS,
    () => get(ENDPOINTS.STATUS),
    {
      onSuccess(data) {
        setStatusData(data);
      },
      onError(error) {
        notify(error.message);
      },
    }
  );

  const deleteStatus = (identifier) => {
    setStatusData((prev) => prev.filter((s) => s.identifier !== identifier));
  };

  const updateStatus = (identifier, newData) => {
    setStatusData((prev) => {
      const currentStatusIndex = prev.findIndex(
        (s) => s.identifier === identifier
      );

      if (currentStatusIndex > -1) {
        const newStatusData = [...prev];
        newStatusData[currentStatusIndex] = {
          ...newStatusData[currentStatusIndex],
          ...newData,
        };
        return newStatusData;
      }

      return prev;
    });
  };

  if (isLoading) return <PageLoader />;
  if (error) return <div>Error: {error}</div>;

  return (
    <StatusDataContext.Provider
      value={{
        isFetching,
        status: statusData,
        deleteStatus,
        updateStatus,
      }}
    >
      {children}
    </StatusDataContext.Provider>
  );
}
