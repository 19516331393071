import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { amber } from "@mui/material/colors";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import BugReportIcon from "@mui/icons-material/BugReport";
import LandscapeIcon from "@mui/icons-material/Landscape";
import NotificationsIcon from "@mui/icons-material/Notifications";
import DashboardIcon from "@mui/icons-material/Dashboard";
import Divider from "@mui/material/Divider";
import { Stack } from "@mui/material";

import MenuIcon from "@mui/icons-material/Menu";
import PersonIcon from "@mui/icons-material/Person";
import LogoutIcon from "@mui/icons-material/Logout";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { visuallyHidden } from "@mui/utils";

import { useAuth } from "contexts/auth";
import { ROUTES } from "lib/routes";
import { getReportIssueUrl } from "lib/support";
import { capitalize } from "lib/text";

import ServicesButton from "components/ServicesButton/ServicesButton";

export default function Header() {
  const [desktopMenuAnchorEl, setDesktopAnchorEl] = useState();
  const [mobileMenuAnchorEl, setMobileMenuAnchorEl] = useState();
  const desktopMenuOpen = !!desktopMenuAnchorEl;
  const mobileMenuOpen = !!mobileMenuAnchorEl;
  const { signout, user } = useAuth();
  const navigate = useNavigate();

  const closeAllMenus = () => {
    handleDesktopMenuClose();
    handleMobileMenuClose();
  };

  const handleAccountClick = () => {
    navigate(ROUTES.ACCOUNT.path);
    closeAllMenus();
  };

  const handleDashboardClick = () => {
    navigate(ROUTES.HOME.path);
    closeAllMenus();
  };

  const handleNotificationsClick = () => {
    navigate(ROUTES.NOTIFICATIONS.path);
    closeAllMenus();
  };

  const handleLogout = () => {
    signout();
    closeAllMenus();
  };

  const handleReportIssue = () => {
    // NOTE: popup blockers?
    window.open(getReportIssueUrl(), "_blank");
    closeAllMenus();
  };

  const handleDesktopMenuClick = (event) => {
    setDesktopAnchorEl(event.currentTarget);
  };

  const handleDesktopMenuClose = () => {
    setDesktopAnchorEl(null);
  };

  const handleMobileMenuClick = (event) => {
    setMobileMenuAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMenuAnchorEl(null);
  };

  return (
    <header>
      <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
        {/* -------------------- MOBILE LAYOUT -------------------- */}
        <Stack
          direction="row"
          sx={{ width: "100%", alignItems: "center", display: { md: "none" } }}
        >
          <Box sx={{ flex: "1 1 auto" }}>
            <Link to={ROUTES.HOME.path}>
              <Typography
                aria-label="status vista"
                component="div"
                sx={{
                  backgroundColor: amber[500],
                  color: "ButtonText",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  borderRadius: "3rem",
                  width: "3rem",
                  height: "3rem",
                  fontWeight: "bold",
                  userSelect: "none",
                }}
                title="StatusVista"
              >
                <Box sx={visuallyHidden}>Home</Box>
                <LandscapeIcon />
              </Typography>
            </Link>
          </Box>
          <ServicesButton />
          <IconButton
            id="mobile-menu-button"
            aria-label="mobile menu"
            aria-controls={mobileMenuOpen ? "mobile-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={mobileMenuOpen ? "true" : undefined}
            onClick={handleMobileMenuClick}
            sx={{
              backgroundColor: "action.hover",
              ml: 1,
            }}
          >
            <MenuIcon />
          </IconButton>
          <Menu
            id="mobile-menu"
            anchorEl={mobileMenuAnchorEl}
            open={mobileMenuOpen}
            onClose={handleMobileMenuClose}
            MenuListProps={{
              "aria-labelledby": "mobile-menu-button",
            }}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <MenuItem disabled sx={{ display: "block", cursor: "auto" }}>
              <Box>
                <Typography variant="caption" fontWeight="bold">
                  Signed in as
                </Typography>
                <Box>{user.attributes.email}</Box>
              </Box>
            </MenuItem>
            <MenuItem onClick={handleAccountClick}>
              <ListItemIcon>
                <PersonIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>Go to account</ListItemText>
            </MenuItem>
            <Divider />
            <MenuItem onClick={handleDashboardClick}>
              <ListItemIcon>
                <DashboardIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>Dashboard</ListItemText>
            </MenuItem>
            <MenuItem onClick={handleNotificationsClick}>
              <ListItemIcon>
                <NotificationsIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>Notifications</ListItemText>
            </MenuItem>
            <Divider />
            <MenuItem aria-label="report issue" onClick={handleReportIssue}>
              <ListItemIcon>
                <BugReportIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>Report issue</ListItemText>
            </MenuItem>
            <MenuItem aria-label="sign out" onClick={handleLogout}>
              <ListItemIcon>
                <LogoutIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>Sign out</ListItemText>
            </MenuItem>
          </Menu>
        </Stack>

        {/* -------------------- DESKTOP LAYOUT -------------------- */}
        <Box
          sx={{
            display: { xs: "none", md: "flex" },
            width: "100%",
            alignItems: "center",
            justifyContent: "end",
          }}
        >
          <ServicesButton />
          <IconButton
            id="account-button"
            aria-label="account menu"
            aria-controls={desktopMenuOpen ? "account-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={desktopMenuOpen ? "true" : undefined}
            onClick={handleDesktopMenuClick}
            sx={{
              backgroundColor: "action.hover",
              ml: 2,
            }}
          >
            <Box
              sx={{
                width: 24,
                height: 24,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontSize: "1.2rem",
              }}
            >
              {capitalize(user.attributes.email.charAt(0))}
            </Box>
          </IconButton>
          <Menu
            id="account-menu"
            anchorEl={desktopMenuAnchorEl}
            open={desktopMenuOpen}
            onClose={handleDesktopMenuClose}
            MenuListProps={{
              "aria-labelledby": "account-button",
            }}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <MenuItem disabled sx={{ display: "block", cursor: "auto" }}>
              <Box>
                <Typography variant="caption" fontWeight="bold">
                  Signed in as
                </Typography>
                <Box>{user.attributes.email}</Box>
              </Box>
            </MenuItem>
            <MenuItem onClick={handleAccountClick}>
              <ListItemIcon>
                <PersonIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>Go to account</ListItemText>
            </MenuItem>
            <Divider />
            <Box sx={{ mt: 1 }}>
              <MenuItem aria-label="report issue" onClick={handleReportIssue}>
                <ListItemIcon>
                  <BugReportIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText>Report issue</ListItemText>
              </MenuItem>
              <MenuItem aria-label="sign out" onClick={handleLogout}>
                <ListItemIcon>
                  <LogoutIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText>Sign out</ListItemText>
              </MenuItem>
            </Box>
          </Menu>
        </Box>
      </Box>
    </header>
  );
}
