import config from "lib/config";

function getAuthUrl(type = "login") {
  const {
    Auth: {
      userPoolWebClientId,
      oauth: { domain, redirectSignIn, responseType, scope },
    },
  } = config;

  switch (type) {
    case "login":
    case "signup":
      return `https://${domain}/${type}?response_type=${responseType}&client_id=${userPoolWebClientId}&redirect_uri=${redirectSignIn}`;
    case "google":
      return `https://${domain}/oauth2/authorize?identity_provider=Google&redirect_uri=${redirectSignIn}&response_type=${responseType}&client_id=${userPoolWebClientId}&scope=${scope.join(
        " "
      )}`;
    default:
      throw new Error("Invalid auth type");
  }
}

export function getAboutUrl() {
  return process.env.REACT_APP_ABOUT_CLIENT_URL;
}

export function getGoogleSignupUrl() {
  return getAuthUrl("google");
}

export function getLoginUrl() {
  return getAuthUrl();
}

export function getSignupUrl() {
  return getAuthUrl("signup");
}

export function getWebClientUrl() {
  // NOTE: this is somewhat brittle
  return config.Auth.oauth.redirectSignIn;
}
