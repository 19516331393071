import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import FilterListIcon from "@mui/icons-material/FilterList";
import {
  Checkbox,
  Chip,
  Divider,
  FormControlLabel,
  List,
  ListItem,
  Paper,
  Stack,
} from "@mui/material";

import { allFilters } from ".";

export function FilterButton({ filtersLength, handleFilterClick }) {
  return (
    <Button
      aria-label="filter"
      fullWidth
      size="small"
      startIcon={<FilterListIcon />}
      onClick={handleFilterClick}
      sx={{
        justifyContent: "flex-start",
        fontWeight: "bold",
        my: { xs: 1, md: 0 },
        pointerEvents: { md: "none" },
        color: { md: "text.primary" },
        textTransform: { md: "none" },
      }}
    >
      Filter ({filtersLength})
    </Button>
  );
}

export function Filters({
  filters,
  handleFilterChange,
  handleFilterClose,
  handleFilterDelete,
}) {
  const filtersLength = Object.keys(filters.applied).length;
  const filtersExist = !!filtersLength;

  return (
    <Stack
      data-testid="filters"
      sx={{
        flex: "1 1 auto",
        height: "100%",
        overflow: "hidden",
      }}
    >
      <Box
        sx={{
          boxShadow: { xs: 2, md: 0 },
          borderBottom: { md: "1px solid" },
          borderColor: { md: "divider" },
        }}
      >
        <Box sx={{ display: { xs: "block", md: "none" } }}>
          <Stack direction="row" sx={{ px: 2, py: 1 }}>
            <Button
              aria-label="filters"
              onClick={handleFilterClose}
              startIcon={<ChevronLeftIcon />}
              sx={{ fontWeight: "bold" }}
            >
              Filters
            </Button>
          </Stack>
          <Divider />
        </Box>
        <Stack
          direction="row"
          justifyContent={filtersExist ? "flex-start" : "center"}
          sx={{ py: 2, px: { xs: 2, md: 0 } }}
        >
          {filtersExist ? (
            <Stack direction="row" sx={{ flexWrap: "wrap", gap: 0.5 }}>
              {filters.applied.map((item) => (
                <Chip
                  key={item.id}
                  name={item.id}
                  label={item.label}
                  onClick={handleFilterDelete(item.id)}
                  onDelete={handleFilterDelete(item.id)}
                  color="primary"
                  variant="outlined"
                />
              ))}
            </Stack>
          ) : (
            <Typography
              variant="subtitle1"
              fontStyle="italic"
              color="GrayText"
              sx={{ py: 0.25 }}
            >
              Viewing all services
            </Typography>
          )}
        </Stack>
      </Box>
      <Box flex="1 1 auto" overflow="hidden">
        <List dense sx={{ height: "100%", overflowY: "scroll" }}>
          {Object.keys(allFilters).map((filterType) => {
            const currentType = allFilters[filterType];

            if (!currentType.visible) {
              return false;
            }

            return (
              <ListItem
                key={currentType.id}
                disableGutters
                sx={{
                  flexDirection: "column",
                  alignItems: "start",
                  pl: { xs: 1, md: 0 },
                  pr: { xs: 1, md: 1 },
                }}
              >
                <Typography variant="caption" fontWeight="bold">
                  {currentType.label}
                </Typography>
                {currentType.items.map((filterItem) => (
                  <FormControlLabel
                    key={filterItem.id}
                    name={filterItem.id}
                    control={
                      <Checkbox
                        checked={
                          !!filters.applied.find((l) => l.id === filterItem.id)
                        }
                        size="small"
                      />
                    }
                    componentsProps={{
                      typography: { variant: "subtitle2" },
                    }}
                    label={filterItem.label}
                    labelPlacement="start"
                    onChange={handleFilterChange}
                    sx={{
                      width: "100%",
                      m: 0,
                      pl: 2,
                      userSelect: "none",
                      "& .MuiFormControlLabel-label": {
                        flex: "1 1 auto",
                      },
                    }}
                  />
                ))}
              </ListItem>
            );
          })}
        </List>
      </Box>
      <Paper
        elevation={1}
        square
        sx={{ p: 2, display: { xs: "block", md: "none" } }}
      >
        <Button
          aria-label="see matches"
          variant="contained"
          fullWidth
          onClick={handleFilterClose}
        >
          See matches
        </Button>
      </Paper>
    </Stack>
  );
}
