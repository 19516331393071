import { Box, Button, Modal, Paper, Slide, Typography } from "@mui/material";

export default function ResultModal({
  closeRoute,
  Icon,
  iconColor,
  heading,
  subheading,
}) {
  const handleModalClose = () => {
    window.location.replace(closeRoute);
  };

  return (
    <Modal open onClose={handleModalClose} sx={{ overflowY: "auto" }}>
      <Slide in direction="down">
        <Box
          sx={{
            py: 2,
            maxWidth: "sm",
            mx: "auto",
          }}
        >
          <Box sx={{ bgcolor: "background.paper" }}>
            <Box
              sx={{
                px: { xs: 2, sm: 12 },
                py: 8,
                textAlign: "center",
              }}
            >
              <Box
                component={Paper}
                elevation={4}
                sx={{
                  backgroundColor: iconColor,
                  width: 100,
                  height: 100,
                  borderRadius: "50%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  color: "white",
                  mx: "auto",
                  mb: 4,
                }}
              >
                <Icon sx={{ width: 60, height: 60 }} />
              </Box>
              <Typography
                variant="h4"
                fontWeight="fontWeightBold"
                sx={{ mb: 2 }}
              >
                {heading}
              </Typography>
              <Typography sx={{ mb: 8 }}>{subheading}</Typography>
              <Button
                variant="contained"
                size="large"
                onClick={handleModalClose}
                sx={{ px: 6 }}
              >
                OK
              </Button>
            </Box>
          </Box>
        </Box>
      </Slide>
    </Modal>
  );
}
