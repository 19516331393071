export const QUERIES = Object.freeze({
  ACCOUNT: {
    GET: "account",
    SEND_VERIFICATION_LINK: "send-verification-link",
    VERIFY_EMAIL: "verify-email",
  },
  DONATIONS: "donations",
  NOTIFICATION: {
    GET_CHANNELS: "notification-get-channels",
    UNSUBSCRIBE: "notifications-unsubscribe",
    UNSUBSCRIBE_ALL: "notifications-unsubscribe-all",
  },
  SERVICE: (serviceId) => `services/${serviceId}`,
  SERVICES: {
    GET: {
      ALL: "services",
    },
  },
  STATUS: "status",
  SUBSCRIPTION: {
    CHECKOUT: (priceId) => `subscription/${priceId}`,
    GET: "subscription",
    PLANS: "subscription-plans",
    PORTAL_SESSION: "subscription-portal-session",
  },
});

export * from "./account";
export * from "./notification";
export * from "./subscription";
