import { useMutation } from "react-query";
import {
  Badge,
  Box,
  Button,
  Grid,
  Skeleton,
  Stack,
  TextField,
} from "@mui/material";
import SendIcon from "@mui/icons-material/Send";

import { useAuth } from "contexts/auth";
import { useNotification } from "contexts/notifications";
import { ENDPOINTS, get, useAccountQuery } from "lib/network";

import PageTitle from "components/PageTitle/PageTitle";
import Subscription from "./Subscription";
import ButtonLoader from "components/ButtonLoader/ButtonLoader";

function AccountDetails({ data }) {
  const { notify } = useNotification();
  const { user } = useAuth();
  const {
    isLoading: isSendVerificationLinkLoading,
    mutate: sendVerificationLink,
  } = useMutation(() => get(ENDPOINTS.ACCOUNT.SEND_VERIFICATION_LINK), {
    onSuccess() {
      notify(`Verification link sent to ${user.attributes.email}`);
    },
    onError(error) {
      notify(error.message);
    },
  });

  const handleVerificationClick = () => {
    sendVerificationLink();
  };

  const { email_verified } = data;

  return (
    <Grid container sx={{ mb: 2 }}>
      <Grid item xs={12} sm="auto">
        <Badge
          badgeContent={email_verified ? "Verified" : "Unverified"}
          color={email_verified ? "success" : "error"}
          sx={{
            "& .MuiBadge-badge": {
              right: email_verified ? "1.25rem" : "1.75rem",
            },
          }}
        >
          <TextField
            label="Email"
            size="small"
            value={user.attributes.email}
            disabled
            sx={{ minWidth: 300 }}
          />
        </Badge>
      </Grid>
      <Grid item xs={12} sm sx={{ display: "flex" }}>
        {!email_verified && (
          <Button
            variant="outlined"
            startIcon={<SendIcon />}
            disabled={isSendVerificationLinkLoading}
            onClick={handleVerificationClick}
            sx={{ ml: { sm: 1 }, mt: { xs: 1, sm: 0 } }}
          >
            Send verification link
            {isSendVerificationLinkLoading && <ButtonLoader />}
          </Button>
        )}
      </Grid>
    </Grid>
  );
}

export default function AccountPage() {
  const { data, isLoading } = useAccountQuery();

  return (
    <Box sx={{ maxWidth: "md" }}>
      <PageTitle title="Account" sx={{ mb: 2 }} />
      {isLoading ? (
        <Stack spacing={1} sx={{ mb: 1 }}>
          <Skeleton animation="wave" variant="rectangular" height={48} />
        </Stack>
      ) : (
        <AccountDetails data={data} />
      )}
      <Subscription />
    </Box>
  );
}
