import React, { createContext, useContext, useState } from "react";
import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

const NotificationContext = createContext();

export function useNotification() {
  return useContext(NotificationContext);
}

export function NotificationProvider({ children }) {
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");

  const handleOpen = (newMessage) => {
    setMessage(newMessage);
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const action = (
    <IconButton
      size="small"
      aria-label="close"
      color="inherit"
      onClick={handleClose}
    >
      <CloseIcon fontSize="small" />
    </IconButton>
  );

  return (
    <NotificationContext.Provider value={{ notify: handleOpen }}>
      {children}
      <Snackbar
        open={open}
        action={action}
        autoHideDuration={3000}
        onClose={handleClose}
        message={message}
      />
    </NotificationContext.Provider>
  );
}
