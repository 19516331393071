import React, { createContext, useContext, useState } from "react";
import { Auth } from "aws-amplify";

import { getAboutUrl } from "lib/auth";
import { useIsDemo } from "lib/config";

const AuthContext = createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [user, setUser] = useState(null);
  const { isDemo } = useIsDemo();

  const signin = (userData) => {
    setUser(userData);
  };

  const signout = () => {
    if (isDemo) {
      window.location.assign(getAboutUrl());
    } else {
      Auth.signOut();
      setUser(null);
    }
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        setUser,
        signin,
        signout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}
