import { useEffect, useState } from "react";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";

import { useSlackAuthMutation } from "lib/network";
import { ROUTES } from "lib/routes";

import PageLoader from "components/Loader/PageLoader";
import ResultModal from "components/ResultModal/ResultModal";

export default function SlackAuthPage() {
  const [ready, setReady] = useState(false);
  const {
    isLoading,
    error,
    mutate: slackAuth,
  } = useSlackAuthMutation({
    onSuccess() {
      setReady(true);
    },
    onError() {
      setReady(true);
    },
  });

  useEffect(() => {
    function initialize() {
      const urlParams = new URLSearchParams(window.location.search);
      const code = urlParams.get("code");

      slackAuth(code);
    }

    initialize();
  }, [slackAuth]);

  if (!ready || isLoading) return <PageLoader />;

  return error ? (
    <ResultModal
      closeRoute={ROUTES.NOTIFICATIONS.path}
      Icon={CloseIcon}
      iconColor="error.light"
      heading="Error"
      subheading={error.message}
    />
  ) : (
    <ResultModal
      closeRoute={ROUTES.NOTIFICATIONS.path}
      Icon={CheckIcon}
      iconColor="success.light"
      heading="Success!"
      subheading="StatusVista has been added to Slack."
    />
  );
}
