import React from "react";
import { Box, Typography } from "@mui/material";

import { CONTACT_EMAIL } from "./support";

const termsVersion = "2022-06-29";
const company = "Logic Now LLC";
const website = "https://app.statusvista.com";

const Heading = ({ children }) => (
  <Typography variant="h5" sx={{ mt: 2, mb: 1 }}>
    {children}
  </Typography>
);

const Paragraph = ({ children }) => (
  <Typography variant="body2" sx={{ mb: 1 }}>
    {children}
  </Typography>
);

export const termsCopy = (
  <Box>
    <Paragraph>Last updated: {termsVersion}</Paragraph>
    <Heading>Terms and Conditions</Heading>
    <Paragraph>
      Please read these Terms and Conditions ("Terms", "Terms and Conditions")
      carefully before using the {website} website (the "Service") operated by{" "}
      {company} ("us", "we", or "our").
    </Paragraph>
    <Paragraph>
      Your access to and use of the Service is conditioned upon your acceptance
      of and compliance with these Terms. These Terms apply to all visitors,
      users and others who wish to access or use the Service.
    </Paragraph>
    <Paragraph>
      By accessing or using the Service you agree to be bound by these Terms. If
      you disagree with any part of the terms then you do not have permission to
      access the Service.
    </Paragraph>

    <Heading>Communications</Heading>
    <Paragraph>
      By creating an Account on our service, you agree to subscribe to
      newsletters, marketing or promotional materials and other information we
      may send. However, you may opt out of receiving any, or all, of these
      communications from us by following the unsubscribe link or instructions
      provided in any email we send.
    </Paragraph>

    <Heading>Purchases</Heading>
    <Paragraph>
      If you wish to purchase any product or service made available through the
      Service ("Purchase"), you may be asked to supply certain information
      relevant to your Purchase including, without limitation, your credit card
      number, the expiration date of your credit card, your billing address, and
      your shipping information.
    </Paragraph>
    <Paragraph>
      You represent and warrant that: (i) you have the legal right to use any
      credit card(s) or other payment method(s) in connection with any Purchase;
      and that (ii) the information you supply to us is true, correct and
      complete.
    </Paragraph>
    <Paragraph>
      The service may employ the use of third party services for the purpose of
      facilitating payment and the completion of Purchases. By submitting your
      information, you grant us the right to provide the information to these
      third parties subject to our Privacy Policy.
    </Paragraph>
    <Paragraph>
      We reserve the right to refuse or cancel your order at any time for
      reasons including but not limited to: product or service availability,
      errors in the description or price of the product or service, error in
      your order or other reasons.
    </Paragraph>
    <Paragraph>
      We reserve the right to refuse or cancel your order if fraud or an
      unauthorized or illegal transaction is suspected.
    </Paragraph>
    <Paragraph></Paragraph>

    <Heading>Availability, Errors and Inaccuracies</Heading>
    <Paragraph>
      We are constantly updating product and service offerings on the Service.
      We may experience delays in updating information on the Service and in our
      advertising on other web sites. The information found on the Service may
      contain errors or inaccuracies and may not be complete or current.
      Products or services may be mispriced, described inaccurately, or
      unavailable on the Service and we cannot guarantee the accuracy or
      completeness of any information found on the Service.
    </Paragraph>
    <Paragraph>
      We therefore reserve the right to change or update information and to
      correct errors, inaccuracies, or omissions at any time without prior
      notice.
    </Paragraph>

    <Heading>Contests, Sweepstakes and Promotions</Heading>
    <Paragraph>
      Any contests, sweepstakes or other promotions (collectively, "Promotions")
      made available through the Service may be governed by rules that are
      separate from these Terms &amp; Conditions. If you participate in any
      Promotions, please review the applicable rules as well as our Privacy
      Policy. If the rules for a Promotion conflict with these Terms and
      Conditions, the Promotion rules will apply.
    </Paragraph>

    <Heading>Subscriptions</Heading>
    <Paragraph>
      Some parts of the Service are billed on a subscription basis
      ("Subscription(s)"). You will be billed in advance on a recurring and
      periodic basis ("Billing Cycle"). Billing cycles are set either on a
      monthly or annual basis, depending on the type of subscription plan you
      select when purchasing a Subscription.
    </Paragraph>
    <Paragraph>
      At the end of each Billing Cycle, your Subscription will automatically
      renew under the exact same conditions unless you cancel it or {company}{" "}
      cancels it. You may cancel your Subscription renewal either through the
      subscription management page or by contacting {company} customer support
      team.
    </Paragraph>
    <Paragraph>
      A valid payment method, including credit card, is required to process the
      payment for your Subscription. You shall provide {company} with accurate
      and complete billing information including full name, address, state, zip
      code, telephone number, and a valid payment method information. By
      submitting such payment information, you automatically authorize
      {company} to charge all Subscription fees incurred through your account to
      any such payment instruments.
    </Paragraph>
    <Paragraph>
      Should automatic billing fail to occur for any reason, {company} will
      issue an electronic invoice indicating that you must proceed manually,
      within a certain deadline date, with the full payment corresponding to the
      billing period as indicated on the invoice.
    </Paragraph>

    <Heading>Free Trial</Heading>
    <Paragraph>
      {company} may, at its sole discretion, offer a Subscription with a free
      trial for a limited period of time ("Free Trial").
    </Paragraph>
    <Paragraph>
      You may be required to enter your billing information in order to sign up
      for the Free Trial.
    </Paragraph>
    <Paragraph>
      If you do enter your billing information when signing up for the Free
      Trial, you will not be charged by {company} until the Free Trial has
      expired. On the last day of the Free Trial period, unless you cancelled
      your Subscription, you will be automatically charged the applicable
      Subscription fees for the type of Subscription you have selected.
    </Paragraph>
    <Paragraph>
      At any time and without notice, {company} reserves the right to (i) modify
      the terms and conditions of the Free Trial offer, or (ii) cancel such Free
      Trial offer.
    </Paragraph>

    <Heading>Fee Changes</Heading>
    <Paragraph>
      {company}, in its sole discretion and at any time, may modify the
      Subscription fees for the Subscriptions. Any Subscription fee change will
      become effective at the end of the then-current Billing Cycle.
    </Paragraph>
    <Paragraph>
      {company} will provide you with a reasonable prior notice of any change in
      Subscription fees to give you an opportunity to terminate your
      Subscription before such change becomes effective.
    </Paragraph>
    <Paragraph>
      Your continued use of the Service after the Subscription fee change comes
      into effect constitutes your agreement to pay the modified Subscription
      fee amount.
    </Paragraph>

    <Heading>Refunds</Heading>
    <Paragraph>
      Except when required by law, all purchases and paid Subscription fees are
      non-refundable.
    </Paragraph>
    <Paragraph>
      If you have any questions about our Returns and Refunds Policy, please
      contact us:
    </Paragraph>
    <Paragraph>
      * By email: <a href={`mailto:${CONTACT_EMAIL}`}>{CONTACT_EMAIL}</a>
    </Paragraph>

    <Heading>Content</Heading>
    <Paragraph>
      Our Service allows you to post, link, store, share and otherwise make
      available certain information, text, graphics, videos, or other material
      ("Content"). You are responsible for the Content that you post on or
      through the Service, including its legality, reliability, and
      appropriateness.
    </Paragraph>
    <Paragraph>
      By posting Content on or through the Service, You represent and warrant
      that: (i) the Content is yours (you own it) and/or you have the right to
      use it and the right to grant us the rights and license as provided in
      these Terms, and (ii) that the posting of your Content on or through the
      Service does not violate the privacy rights, publicity rights, copyrights,
      contract rights or any other rights of any person or entity. We reserve
      the right to terminate the account of anyone found to be infringing on a
      copyright.
    </Paragraph>
    <Paragraph>
      You retain any and all of your rights to any Content you submit, post or
      display on or through the Service and you are responsible for protecting
      those rights. We take no responsibility and assume no liability for
      Content you or any third party posts on or through the Service. However,
      by posting Content using the Service you grant us the right and license to
      use, modify, reproduce, and distribute such Content on and through the
      Service. You agree that this license includes the right for us to make
      your Content available to other users of the Service whom you have shared
      it with, who may also use your Content subject to these Terms.
    </Paragraph>
    <Paragraph>
      {company} has the right but not the obligation to monitor and edit all
      Content provided by users.
    </Paragraph>
    <Paragraph>
      In addition, Content found on or through this Service are the property of{" "}
      {company} or used with permission. You may not distribute, modify,
      transmit, reuse, download, repost, copy, or use said Content, whether in
      whole or in part, for commercial purposes or for personal gain, without
      express advance written permission from us.
    </Paragraph>

    <Heading>Accounts</Heading>
    <Paragraph>
      When you create an account with us, you guarantee that you are above the
      age of 13, and that the information you provide us is accurate, complete,
      and current at all times. Inaccurate, incomplete, or obsolete information
      may result in the immediate termination of your account on the Service.
    </Paragraph>
    <Paragraph>
      You are responsible for maintaining the confidentiality of your account
      and password, including but not limited to the restriction of access to
      your computer and/or account. You agree to accept responsibility for any
      and all activities or actions that occur under your account and/or
      password, whether your password is with our Service or a third-party
      service. You must notify us immediately upon becoming aware of any breach
      of security or unauthorized use of your account.
    </Paragraph>
    <Paragraph>
      You may not use as a username the name of another person or entity or that
      is not lawfully available for use, a name or trademark that is subject to
      any rights of another person or entity other than you, without appropriate
      authorization. You may not use as a username any name that is offensive,
      vulgar or obscene.
    </Paragraph>
    <Paragraph>
      We reserve the right to refuse service, terminate accounts, remove or edit
      content, or cancel orders in our sole discretion.
    </Paragraph>

    <Heading>Copyright Policy</Heading>
    <Paragraph>
      We respect the intellectual property rights of others. It is our policy to
      respond to any claim that Content posted on the Service infringes on the
      copyright or other intellectual property rights ("Infringement") of any
      person or entity.
    </Paragraph>
    <Paragraph>
      If you are a copyright owner, or authorized on behalf of one, and you
      believe that the copyrighted work has been copied in a way that
      constitutes copyright infringement, please submit your claim via email to{" "}
      <a href={`mailto:${CONTACT_EMAIL}`}>{CONTACT_EMAIL}</a>, with the subject
      line: "Copyright Infringement" and include in your claim a detailed
      description of the alleged Infringement as detailed below, under "DMCA
      Notice and Procedure for Copyright Infringement Claims"
    </Paragraph>
    <Paragraph>
      You may be held accountable for damages (including costs and attorneys'
      fees) for misrepresentation or bad-faith claims on the infringement of any
      Content found on and/or through the Service on your copyright.
    </Paragraph>
    <Paragraph>
      DMCA Notice and Procedure for Copyright Infringement Claims
      -----------------------------------------------------------
    </Paragraph>
    <Paragraph>
      You may submit a notification pursuant to the Digital Millennium Copyright
      Act (DMCA) by providing our Copyright Agent with the following information
      in writing (see 17 U.S.C 512(c)(3) for further detail):
    </Paragraph>
    <Paragraph>
      * an electronic or physical signature of the person authorized to act on
      behalf of the owner of the copyright's interest;
    </Paragraph>
    <Paragraph>
      * a description of the copyrighted work that you claim has been infringed,
      including the URL (i.e., web page address) of the location where the
      copyrighted work exists or a copy of the copyrighted work;
    </Paragraph>
    <Paragraph>
      * identification of the URL or other specific location on the Service
      where the material that you claim is infringing is located;
    </Paragraph>
    <Paragraph>* your address, telephone number, and email address;</Paragraph>
    <Paragraph>
      * a statement by you that you have a good faith belief that the disputed
      use is not authorized by the copyright owner, its agent, or the law;
    </Paragraph>
    <Paragraph>
      * a statement by you, made under penalty of perjury, that the above
      information in your notice is accurate and that you are the copyright
      owner or authorized to act on the copyright owner's behalf.
    </Paragraph>
    <Paragraph>
      You can contact our Copyright Agent via email at{" "}
      <a href={`mailto:${CONTACT_EMAIL}`}>{CONTACT_EMAIL}</a>
    </Paragraph>

    <Heading>Intellectual Property</Heading>
    <Paragraph>
      The Service and its original content (excluding Content provided by
      users), features and functionality are and will remain the exclusive
      property of {company} and its licensors. The Service is protected by
      copyright, trademark, and other laws of both the United States and foreign
      countries. Our trademarks and trade dress may not be used in connection
      with any product or service without the prior written consent of {company}
      .
    </Paragraph>

    <Heading>Links To Other Web Sites</Heading>
    <Paragraph>
      Our Service may contain links to third party web sites or services that
      are not owned or controlled by {company}
    </Paragraph>
    <Paragraph>
      {company} has no control over, and assumes no responsibility for the
      content, privacy policies, or practices of any third party web sites or
      services. We do not warrant the offerings of any of these
      entities/individuals or their websites.
    </Paragraph>
    <Paragraph>
      You acknowledge and agree that {company} shall not be responsible or
      liable, directly or indirectly, for any damage or loss caused or alleged
      to be caused by or in connection with use of or reliance on any such
      content, goods or services available on or through any such third party
      web sites or services.
    </Paragraph>
    <Paragraph>
      We strongly advise you to read the terms and conditions and privacy
      policies of any third party web sites or services that you visit.
    </Paragraph>

    <Heading>Termination</Heading>
    <Paragraph>
      We may terminate or suspend your account and bar access to the Service
      immediately, without prior notice or liability, under our sole discretion,
      for any reason whatsoever and without limitation, including but not
      limited to a breach of the Terms.
    </Paragraph>
    <Paragraph>
      If you wish to terminate your account, you may simply discontinue using
      the Service.
    </Paragraph>
    <Paragraph>
      All provisions of the Terms which by their nature should survive
      termination shall survive termination, including, without limitation,
      ownership provisions, warranty disclaimers, indemnity and limitations of
      liability.
    </Paragraph>

    <Heading>Indemnification</Heading>
    <Paragraph>
      You agree to defend, indemnify and hold harmless {company} and its
      licensee and licensors, and their employees, contractors, agents, officers
      and directors, from and against any and all claims, damages, obligations,
      losses, liabilities, costs or debt, and expenses (including but not
      limited to attorney's fees), resulting from or arising out of a) your use
      and access of the Service, by you or any person using your account and
      password; b) a breach of these Terms, or c) Content posted on the Service.
    </Paragraph>

    <Heading>Limitation Of Liability</Heading>
    <Paragraph>
      In no event shall {company}, nor its directors, employees, partners,
      agents, suppliers, or affiliates, be liable for any indirect, incidental,
      special, consequential or punitive damages, including without limitation,
      loss of profits, data, use, goodwill, or other intangible losses,
      resulting from (i) your access to or use of or inability to access or use
      the Service; (ii) any conduct or content of any third party on the
      Service; (iii) any content obtained from the Service; and (iv)
      unauthorized access, use or alteration of your transmissions or content,
      whether based on warranty, contract, tort (including negligence) or any
      other legal theory, whether or not we have been informed of the
      possibility of such damage, and even if a remedy set forth herein is found
      to have failed of its essential purpose.
    </Paragraph>

    <Heading>Disclaimer</Heading>
    <Paragraph>
      Your use of the Service is at your sole risk. The Service is provided on
      an "AS IS" and "AS AVAILABLE" basis. The Service is provided without
      warranties of any kind, whether express or implied, including, but not
      limited to, implied warranties of merchantability, fitness for a
      particular purpose, non- infringement or course of performance.
    </Paragraph>
    <Paragraph>
      {company} its subsidiaries, affiliates, and its licensors do not warrant
      that a) the Service will function uninterrupted, secure or available at
      any particular time or location; b) any errors or defects will be
      corrected; c) the Service is free of viruses or other harmful components;
      or d) the results of using the Service will meet your requirements.
    </Paragraph>

    <Heading>Exclusions</Heading>
    <Paragraph>
      Some jurisdictions do not allow the exclusion of certain warranties or the
      exclusion or limitation of liability for consequential or incidental
      damages, so the limitations above may not apply to you.
    </Paragraph>

    <Heading>Governing Law</Heading>
    <Paragraph>
      These Terms shall be governed and construed in accordance with the laws of
      Colorado, United States, without regard to its conflict of law provisions.
    </Paragraph>
    <Paragraph>
      Our failure to enforce any right or provision of these Terms will not be
      considered a waiver of those rights. If any provision of these Terms is
      held to be invalid or unenforceable by a court, the remaining provisions
      of these Terms will remain in effect. These Terms constitute the entire
      agreement between us regarding our Service, and supersede and replace any
      prior agreements we might have had between us regarding the Service.
    </Paragraph>

    <Heading>Changes</Heading>
    <Paragraph>
      We reserve the right, at our sole discretion, to modify or replace these
      Terms at any time. If a revision is material we will provide at least 30
      days notice prior to any new terms taking effect. What constitutes a
      material change will be determined at our sole discretion.
    </Paragraph>
    <Paragraph>
      By continuing to access or use our Service after any revisions become
      effective, you agree to be bound by the revised terms. If you do not agree
      to the new terms, you are no longer authorized to use the Service.
    </Paragraph>

    <Heading>Contact Us</Heading>
    <Paragraph>
      If you have any questions about these Terms, please contact us.
    </Paragraph>
    <Paragraph>
      <a href={`mailto:${CONTACT_EMAIL}`}>{CONTACT_EMAIL}</a>
    </Paragraph>
  </Box>
);

export const privacyCopy = (
  <Box>
    <Paragraph>Last updated: {termsVersion}</Paragraph>
    <Heading>Privacy Policy</Heading>
    <Paragraph>
      {company} ("us", "we", or "our") operates the {website} website (the
      "Service").
    </Paragraph>
    <Paragraph>
      This page informs you of our policies regarding the collection, use and
      disclosure of Personal Information when you use our Service.
    </Paragraph>
    <Paragraph>
      We will not use or share your information with anyone except as described
      in this Privacy Policy.
    </Paragraph>
    <Paragraph>
      We use your Personal Information for providing and improving the Service.
      By using the Service, you agree to the collection and use of information
      in accordance with this policy. Unless otherwise defined in this Privacy
      Policy, terms used in this Privacy Policy have the same meanings as in our
      Terms and Conditions, accessible at
      {website}/terms.
    </Paragraph>

    <Heading>Information Collection And Use</Heading>
    <Paragraph>
      While using our Service, we may ask you to provide us with certain
      personally identifiable information that can be used to contact or
      identify you. Personally identifiable information may include, but is not
      limited to, your email address, phone number, postal address, other
      information ("Personal Information").
    </Paragraph>
    <Paragraph>
      We collect this information for the purpose of providing the Service,
      identifying and communicating with you, responding to your
      requests/inquiries, servicing your purchase orders, and improving our
      services.
    </Paragraph>

    <Heading>Log Data</Heading>
    <Paragraph>
      We may also collect information that your browser sends whenever you visit
      our Service ("Log Data"). This Log Data may include information such as
      your computer's Internet Protocol ("IP") address, browser type, browser
      version, the pages of our Service that you visit, the time and date of
      your visit, the time spent on those pages and other statistics.
    </Paragraph>

    <Heading>Cookies</Heading>
    <Paragraph>
      Cookies are files with a small amount of data, which may include an
      anonymous unique identifier. Cookies are sent to your browser from a web
      site and transferred to your device. We use cookies to collect information
      in order to improve our services for you.
    </Paragraph>
    <Paragraph>
      You can instruct your browser to refuse all cookies or to indicate when a
      cookie is being sent. The Help feature on most browsers provide
      information on how to accept cookies, disable cookies or to notify you
      when receiving a new cookie.
    </Paragraph>
    <Paragraph>
      If you do not accept cookies, you may not be able to use some features of
      our Service and we recommend that you leave them turned on.
    </Paragraph>

    {/* <Heading>Do Not Track Disclosure</Heading>
      <Paragraph>
        We support Do Not Track ("DNT"). Do Not Track is a preference you can
        set in your web browser to inform websites that you do not want to be
        tracked.
      </Paragraph>
      <Paragraph>
        You can enable or disable Do Not Track by visiting the Preferences or
        Settings page of your web browser.
      </Paragraph> */}

    <Heading>Service Providers</Heading>
    <Paragraph>
      We may employ third party companies and individuals to facilitate our
      Service, to provide the Service on our behalf, to perform Service-related
      services and/or to assist us in analyzing how our Service is used.
    </Paragraph>
    <Paragraph>
      These third parties have access to your Personal Information only to
      perform specific tasks on our behalf and are obligated not to disclose or
      use your information for any other purpose.
    </Paragraph>

    <Heading>Communications</Heading>
    <Paragraph>
      We may use your Personal Information to contact you with newsletters,
      marketing or promotional materials and other information that may be of
      interest to you. You may opt out of receiving any, or all, of these
      communications from us by following the unsubscribe link or instructions
      provided in any email we send or by contacting us.
    </Paragraph>

    <Heading>Compliance With Laws</Heading>
    <Paragraph>
      We will disclose your Personal Information where required to do so by law
      or subpoena or if we believe that such action is necessary to comply with
      the law and the reasonable requests of law enforcement or to protect the
      security or integrity of our Service.
    </Paragraph>

    <Heading>Business Transaction</Heading>
    <Paragraph>
      If {company} is involved in a merger, acquisition or asset sale, your
      Personal Information may be transferred as a business asset. In such
      cases, we will provide notice before your Personal Information is
      transferred and/or becomes subject to a different Privacy Policy.
    </Paragraph>

    <Heading>Security</Heading>
    <Paragraph>
      The security of your Personal Information is important to us, and we
      strive to implement and maintain reasonable, commercially acceptable
      security procedures and practices appropriate to the nature of the
      information we store, in order to protect it from unauthorized access,
      destruction, use, modification, or disclosure.
    </Paragraph>
    <Paragraph>
      However, please be aware that no method of transmission over the internet,
      or method of electronic storage is 100% secure and we are unable to
      guarantee the absolute security of the Personal Information we have
      collected from you.
    </Paragraph>

    <Heading>International Transfer</Heading>
    <Paragraph>
      Your information, including Personal Information, may be transferred to —
      and maintained on — computers located outside of your state, province,
      country or other governmental jurisdiction where the data protection laws
      may differ than those from your jurisdiction.
    </Paragraph>
    <Paragraph>
      If you are located outside United States and choose to provide information
      to us, please note that we transfer the information, including Personal
      Information, to United States and process it there.
    </Paragraph>
    <Paragraph>
      Your consent to this Privacy Policy followed by your submission of such
      information represents your agreement to that transfer.
    </Paragraph>

    <Heading>Links To Other Sites</Heading>
    <Paragraph>
      Our Service may contain links to other sites that are not operated by us.
      If you click on a third party link, you will be directed to that third
      party's site. We strongly advise you to review the Privacy Policy of every
      site you visit.
    </Paragraph>
    <Paragraph>
      We have no control over, and assume no responsibility for the content,
      privacy policies or practices of any third party sites or services.
    </Paragraph>

    <Heading>Children's Privacy</Heading>
    <Paragraph>
      Only persons age 13 or older have permission to access our Service. Our
      Service does not address anyone under the age of 13 ("Children").
    </Paragraph>
    <Paragraph>
      We do not knowingly collect personally identifiable information from
      children under 13. If you are a parent or guardian and you learn that your
      Children have provided us with Personal Information, please contact us. If
      we become aware that we have collected Personal Information from a child
      under age 13 without verification of parental consent, we take steps to
      remove that information from our servers.
    </Paragraph>

    <Heading>Changes To This Privacy Policy</Heading>
    <Paragraph>
      This Privacy Policy is effective as of {termsVersion} and will remain in
      effect except with respect to any changes in its provisions in the future,
      which will be in effect immediately after being posted on this page.
    </Paragraph>
    <Paragraph>
      We reserve the right to update or change our Privacy Policy at any time
      and you should check this Privacy Policy periodically. Your continued use
      of the Service after we post any modifications to the Privacy Policy on
      this page will constitute your acknowledgment of the modifications and
      your consent to abide and be bound by the modified Privacy Policy.
    </Paragraph>
    <Paragraph>
      If we make any material changes to this Privacy Policy, we will notify you
      either through the email address you have provided us, or by placing a
      prominent notice on our website.
    </Paragraph>

    <Heading>Contact Us</Heading>
    <Paragraph>
      If you have any questions about this Privacy Policy, please contact us.
    </Paragraph>
    <Paragraph>
      <a href={`mailto:${CONTACT_EMAIL}`}>{CONTACT_EMAIL}</a>
    </Paragraph>
  </Box>
);
