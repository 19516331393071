import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import { getStatus } from "lib/status";

export default function StatusIndicator({
  status_description,
  status_indicator,
  status_source_type,
}) {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box
        sx={{
          backgroundColor: getStatus(status_source_type, status_indicator)
            .color,
          borderRadius: "1rem",
          width: "0.75rem",
          height: "0.75rem",
          minWidth: "0.75rem",
          minHeight: "0.75rem",
        }}
      />
      <Typography
        variant="body2"
        sx={{
          ml: 1,
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
        }}
      >
        {status_description}
      </Typography>
    </Box>
  );
}
