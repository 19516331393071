import { useRef, useState } from "react";
import SendIcon from "@mui/icons-material/Send";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemIcon,
  Skeleton,
  Slide,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { merge } from "lodash";

import { useAuth } from "contexts/auth";
import { getWebClientUrl } from "lib/auth";
import {
  useGetNotificationChannelsQuery,
  useSendTestSlackMessageMutation,
  useUpdateNotificationChannelMutation,
} from "lib/network";
import { ROUTES } from "lib/routes";
import { CONTACT_EMAIL } from "lib/support";

import ButtonLoader from "components/ButtonLoader/ButtonLoader";

const slackSteps = [
  <>
    <Box>
      If you haven't done so already, add the StatusVista app to your Slack
      workspace
    </Box>
    <Box sx={{ ml: 1 }}>
      <AddToSlackButton />
    </Box>
  </>,
  <span>
    Optionally, create a new channel in Slack if you don't already have one
  </span>,
  <span>
    Right click the channel you want to receive notifications and select{" "}
    <strong>View channel details</strong>
  </span>,
  <span>
    Select the <strong>Integrations</strong> tab
  </span>,
  <span>
    Click <strong>Add apps</strong> if the channel has existing apps or{" "}
    <strong>Add an app</strong> if none exist
  </span>,
  <span>
    Click the <strong>Add</strong> button beside <strong>StatusVista</strong>
  </span>,
  <span>
    Right click the channel again and select{" "}
    <strong>View channel details</strong>
  </span>,
  <span>
    Find <strong>Channel ID</strong> near the bottom of the{" "}
    <strong>About</strong> tab and click the copy button
  </span>,
  <span>Paste the Channel ID into the text box inside StatusVista</span>,
  <span>
    Click the <strong>Send test message</strong> button to verify your
    integration
  </span>,
  <span>
    If you need additional help, please{" "}
    <a href={`mailto:${CONTACT_EMAIL}`}>
      <Typography sx={{ color: "primary.main", display: "inline" }}>
        contact support
      </Typography>
    </a>
  </span>,
];

function AddToSlackButton() {
  const redirectUri = `${getWebClientUrl()}${ROUTES.SLACK_AUTH.path}`;

  return (
    <a
      href={`https://slack.com/oauth/v2/authorize?client_id=4003647020788.4003649319860&scope=chat:write&user_scope=&redirect_uri=${redirectUri}`}
      style={{ display: "flex", alignItems: "center" }}
    >
      <img
        alt="Add to Slack"
        height="40"
        width="139"
        src="https://platform.slack-edge.com/img/add_to_slack.png"
        srcSet="https://platform.slack-edge.com/img/add_to_slack.png 1x, https://platform.slack-edge.com/img/add_to_slack@2x.png 2x"
      />
    </a>
  );
}

export default function ChannelSettings() {
  const { user } = useAuth();
  const [channelsData, setChannelsData] = useState();
  const [slackChannelId, setSlackChannelId] = useState("");
  const [helpOpen, setHelpOpen] = useState(false);
  const slackChannelIdRef = useRef();
  const helpContainerRef = useRef();
  const {
    data: channelsQueryData,
    isLoading: isNotificationSettingsLoading,
    error: notificationSettingsError,
  } = useGetNotificationChannelsQuery({
    onSuccess(data) {
      const {
        slack: { slack_channel_id },
      } = data;

      setSlackChannelId(slack_channel_id || "");
      setChannelsData(data);
    },
  });
  const {
    isLoading: isSendTestSlackMessageLoading,
    mutate: sendTestSlackMessage,
  } = useSendTestSlackMessageMutation();
  const { mutate: updateChannel } = useUpdateNotificationChannelMutation({
    onSuccess(data) {
      const { type, ...rest } = data;

      setChannelsData((prev) => ({
        ...prev,
        [type]: rest,
      }));
    },
  });

  const handleChannelChange = (type) => (_, newChecked) => {
    const currentValue = channelsData[type];

    updateChannel({
      ...currentValue,
      type,
      enabled: newChecked,
    });
  };

  const handleSlackChannelIdChange = ({ target: { value } }) => {
    setSlackChannelId(value);

    setTimeout(() => {
      const { current } = slackChannelIdRef;

      if (current) {
        const { value: currentValue } = current;

        if (currentValue === value) {
          const { slack: currentSlack } = channelsData;

          updateChannel({
            ...currentSlack,
            type: "slack",
            slack_channel_id: currentValue,
          });
        }
      }
    }, 500);
  };

  const handleHelpClick = () => {
    setHelpOpen(!helpOpen);
  };

  const handleSendTestMessageClick = () => {
    sendTestSlackMessage(slackChannelId);
  };

  if (notificationSettingsError) {
    return (
      <Alert severity="error">
        <AlertTitle>Channels error</AlertTitle>
        Something went wrong getting your channels
      </Alert>
    );
  }

  const mergedChannelsData = merge(channelsQueryData, channelsData);
  const slackChannelIdValue =
    slackChannelId ?? mergedChannelsData?.slack?.slack_channel_id;

  return (
    <Box sx={{ mb: 4 }}>
      <Typography variant="h6">Channels</Typography>
      <Typography variant="body2" sx={{ mb: 1 }}>
        Choose how you want to be notified of status updates
      </Typography>
      {isNotificationSettingsLoading ? (
        <Stack spacing={1}>
          <Stack direction="row" spacing={1}>
            <Skeleton
              animation="wave"
              variant="rectangular"
              width={120}
              height={39}
            />
            <Skeleton
              animation="wave"
              variant="rectangular"
              height={39}
              sx={{ flex: "1 1 auto" }}
            />
          </Stack>
          <Stack direction="row" spacing={1}>
            <Skeleton
              animation="wave"
              variant="rectangular"
              width={120}
              height={39}
            />
            <Skeleton
              animation="wave"
              variant="rectangular"
              height={39}
              sx={{ flex: "1 1 auto" }}
            />
          </Stack>
        </Stack>
      ) : (
        <Box>
          <Grid
            container
            sx={{
              flexWrap: { sm: "nowrap" },
              mb: 1,
            }}
          >
            <Grid
              item
              sx={{
                display: "flex",
                alignItems: "center",
                width: { xs: "100%", sm: "auto" },
              }}
            >
              <FormControlLabel
                label="Email"
                control={
                  <Switch
                    checked={mergedChannelsData?.email.enabled || false}
                    onChange={handleChannelChange("email")}
                  />
                }
                sx={{ minWidth: 120, m: 0 }}
              />
              <Typography
                sx={{
                  color: "text.disabled",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {user.attributes.email}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            sx={{
              flexWrap: { sm: "nowrap" },
            }}
          >
            <Grid
              item
              sx={{
                display: "flex",
                alignItems: "center",
                width: { xs: "100%", sm: "auto" },
                mb: { xs: 1, sm: 0 },
                mr: { xs: 0, sm: 1 },
              }}
            >
              <FormControlLabel
                label="Slack"
                control={
                  <Switch
                    checked={mergedChannelsData?.slack.enabled || false}
                    onChange={handleChannelChange("slack")}
                  />
                }
                sx={{ minWidth: 120, m: 0 }}
              />
              {mergedChannelsData?.slack.slack_authorized ? (
                <TextField
                  inputRef={slackChannelIdRef}
                  size="small"
                  label="Channel ID"
                  value={slackChannelIdValue}
                  onChange={handleSlackChannelIdChange}
                  error={
                    mergedChannelsData?.slack.enabled &&
                    !slackChannelIdValue.length
                  }
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="help"
                          edge="end"
                          onClick={handleHelpClick}
                        >
                          <HelpOutlineIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  sx={{ flex: "1 1 auto" }}
                />
              ) : (
                <AddToSlackButton />
              )}
            </Grid>
            <Grid
              item
              xs={12}
              sm="auto"
              sx={{
                display: "flex",
                justifyContent: { xs: "end", sm: "auto" },
              }}
            >
              {mergedChannelsData?.slack.slack_authorized && (
                <Button
                  variant="outlined"
                  disabled={
                    !slackChannelIdValue.length || isSendTestSlackMessageLoading
                  }
                  startIcon={<SendIcon />}
                  onClick={handleSendTestMessageClick}
                >
                  Send test message
                  {isSendTestSlackMessageLoading && <ButtonLoader />}
                </Button>
              )}
            </Grid>
          </Grid>
          <Box ref={helpContainerRef} sx={{ overflow: "hidden" }}>
            <Slide
              in={helpOpen}
              mountOnEnter
              unmountOnExit
              container={helpContainerRef?.current}
            >
              <Box sx={{ mt: 2 }}>
                <Typography variant="h6">Adding to Slack</Typography>
                <List dense>
                  {slackSteps.map((step, idx) => (
                    <ListItem key={idx}>
                      <ListItemIcon>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            borderRadius: "50%",
                            backgroundColor: "divider",
                            width: 32,
                            height: 32,
                          }}
                        >
                          <Typography variant="body2">{idx + 1}</Typography>
                        </Box>
                      </ListItemIcon>
                      {step}
                    </ListItem>
                  ))}
                </List>
              </Box>
            </Slide>
          </Box>
        </Box>
      )}
    </Box>
  );
}
