// https://cs50.statuspage.io/api
// https://developer.statuspage.io/

export const STATUS = Object.freeze({
  AWS_CURRENT_EVENTS_API: {
    DISRUPTION: {
      id: "disruption",
      label: "Disruption",
      chartValue: 3,
      color: "error.light",
      priority: 1,
    },
    DEGRADATION: {
      id: "degradation",
      label: "Degradation",
      chartValue: 2,
      color: "warning.light",
      priority: 2,
    },
    INFORMATIONAL: {
      id: "informational",
      label: "Informational",
      chartValue: 1,
      color: "info.light",
      priority: 3,
    },
    RESOLVED: {
      id: "resolved",
      label: "Resolved",
      chartValue: 0,
      color: "success.light",
      priority: 4,
    },
  },
  STATUSPAGE_V2_API: {
    CRITICAL: {
      id: "critical",
      label: "Critical",
      chartValue: 4,
      color: "error.light",
      priority: 1,
    },
    MAJOR: {
      id: "major",
      label: "Major",
      chartValue: 3,
      color: "warning.light",
      priority: 2,
    },
    MINOR: {
      id: "minor",
      label: "Minor",
      chartValue: 2,
      color: "info.light",
      priority: 3,
    },
    MAINTENANCE: {
      id: "maintenance",
      label: "Maintenance",
      chartValue: 1,
      color: "info.light",
      priority: 4,
    },
    NONE: {
      id: "none",
      label: "None",
      chartValue: 0,
      color: "success.light",
      priority: 5,
    },
  },
});

export function isServiceDown(statusSourceType, statusIndicator) {
  switch (statusSourceType) {
    case "AWS_CURRENT_EVENTS_API":
      return statusIndicator !== "resolved";
    case "STATUSPAGE_V2_API":
      return statusIndicator !== "none";
    default:
      throw new Error("Invalid source type provided to isServiceDown");
  }
}

export function getStatus(statusSourceType, statusIndicator) {
  const statusKeys = Object.keys(STATUS[statusSourceType]);
  const knownStatusKey = statusKeys.find(
    (k) => STATUS[statusSourceType][k].id === statusIndicator
  );

  return knownStatusKey && STATUS[statusSourceType][knownStatusKey];
}

export function getStatusFromValue(statusSourceType, chartValue) {
  const statusKeys = Object.keys(STATUS[statusSourceType]);
  const knownStatusKey = statusKeys.find(
    (k) => STATUS[statusSourceType][k].chartValue === chartValue
  );

  return knownStatusKey && STATUS[statusSourceType][knownStatusKey];
}
