const awsIdentifierPrefix = "aws_";

export const PROVIDERS_FILTER_ID = "providers";
export const AWS_REGIONS_FILTER_ID = "aws_regions";
export const TEXT_SEARCH_FILTER_ID = "text_search";
export const allFilters = {
  [PROVIDERS_FILTER_ID]: {
    id: PROVIDERS_FILTER_ID,
    visible: true,
    label: "Cloud Provider",
    items: [
      {
        id: `${PROVIDERS_FILTER_ID}:other`,
        label: "Other",
      },
      {
        id: `${PROVIDERS_FILTER_ID}:aws`,
        label: "AWS",
      },
    ],
  },
  // https://raw.githubusercontent.com/jsonmaur/aws-regions/master/regions.json
  [AWS_REGIONS_FILTER_ID]: {
    id: AWS_REGIONS_FILTER_ID,
    visible: true,
    label: "AWS Region",
    items: [
      {
        id: `${AWS_REGIONS_FILTER_ID}:us-east-1`,
        label: "N. Virginia (us-east-1)",
      },
      {
        id: `${AWS_REGIONS_FILTER_ID}:us-east-2`,
        label: "Ohio (us-east-2)",
      },
      {
        id: `${AWS_REGIONS_FILTER_ID}:us-west-1`,
        label: "N. California (us-west-1)",
      },
      {
        id: `${AWS_REGIONS_FILTER_ID}:us-west-2`,
        label: "Oregon (us-west-2)",
      },
      {
        id: `${AWS_REGIONS_FILTER_ID}:ca-central-1`,
        label: "CA Central (ca-central-1)",
      },
      {
        id: `${AWS_REGIONS_FILTER_ID}:eu-west-1`,
        label: "Ireland (eu-west-1)",
      },
      {
        id: `${AWS_REGIONS_FILTER_ID}:eu-west-2`,
        label: "London (eu-west-2)",
      },
      {
        id: `${AWS_REGIONS_FILTER_ID}:eu-west-3`,
        label: "Paris (eu-west-3)",
      },
      {
        id: `${AWS_REGIONS_FILTER_ID}:eu-central-1`,
        label: "Frankfurt (eu-central-1)",
      },
      {
        id: `${AWS_REGIONS_FILTER_ID}:eu-north-1`,
        label: "Stockholm (eu-north-1)",
      },
      {
        id: `${AWS_REGIONS_FILTER_ID}:eu-south-1`,
        label: "Milan (eu-south-1)",
      },
      {
        id: `${AWS_REGIONS_FILTER_ID}:af-south-1`,
        label: "Cape Town (af-south-1)",
      },
      {
        id: `${AWS_REGIONS_FILTER_ID}:ap-east-1`,
        label: "Hong Kong (ap-east-1)",
      },
      {
        id: `${AWS_REGIONS_FILTER_ID}:ap-south-1`,
        label: "Mumbai (ap-south-1)",
      },
      {
        id: `${AWS_REGIONS_FILTER_ID}:ap-northeast-1`,
        label: "Tokyo (ap-northeast-1)",
      },
      {
        id: `${AWS_REGIONS_FILTER_ID}:ap-northeast-2`,
        label: "Seoul (ap-northeast-2)",
      },
      {
        id: `${AWS_REGIONS_FILTER_ID}:ap-northeast-3`,
        label: "Osaka (ap-northeast-3)",
      },
      {
        id: `${AWS_REGIONS_FILTER_ID}:ap-southeast-1`,
        label: "Singapore (ap-southeast-1)",
      },
      {
        id: `${AWS_REGIONS_FILTER_ID}:ap-southeast-2`,
        label: "Sydney (ap-southeast-2)",
      },
      {
        id: `${AWS_REGIONS_FILTER_ID}:ap-southeast-3`,
        label: "Jakarta (ap-southeast-3)",
      },
      {
        id: `${AWS_REGIONS_FILTER_ID}:sa-east-1`,
        label: "São Paulo (sa-east-1)",
      },
      {
        id: `${AWS_REGIONS_FILTER_ID}:me-south-1`,
        label: "Bahrain (me-south-1)",
      },
    ],
  },
  [TEXT_SEARCH_FILTER_ID]: {
    id: TEXT_SEARCH_FILTER_ID,
    visible: false,
  },
};

export function getAvailableServices(services, status) {
  return services.filter(
    (service) =>
      !status.find((status) => service.identifier === status.identifier)
  );
}

export function getFilteredServices(services, filters) {
  const { applied, values } = filters;
  const { fuse, value: textSearchValue } = values[TEXT_SEARCH_FILTER_ID] || {};

  if (!applied.length && !textSearchValue) return services;

  let filteredServices = [];

  if (!applied.length) {
    filteredServices = [[...services]];
  } else {
    const appliedTypes = applied.reduce((acc, val) => {
      const filterType = val.id.split(":")[0];
      const exists = acc.includes(filterType);

      if (exists) return acc;

      return [...acc, filterType];
    }, []);

    const appliedRegions = applied
      .filter((a) => a.id.startsWith(AWS_REGIONS_FILTER_ID))
      .map((a) => a.id.split(":")[1]);

    Object.keys(allFilters).forEach((type) => {
      const appliedItems = applied.filter((a) => a.id.split(":")[0] === type);

      // filter services by type
      switch (type) {
        case PROVIDERS_FILTER_ID: {
          appliedItems.forEach((i) => {
            const filterId = i.id.split(":")[1];

            switch (filterId) {
              case "aws": {
                const awsServices = services.filter((s) =>
                  s.identifier.startsWith(awsIdentifierPrefix)
                );

                if (appliedTypes.includes(AWS_REGIONS_FILTER_ID)) {
                  // filter by regions
                  filteredServices.push(
                    awsServices.filter((s) =>
                      appliedRegions.some((r) => s.identifier.endsWith(r))
                    )
                  );
                } else {
                  // return all aws services
                  filteredServices.push(awsServices);
                }
                break;
              }
              case "other": {
                filteredServices.push(
                  services.filter(
                    (s) => !s.identifier.startsWith(awsIdentifierPrefix)
                  )
                );
                break;
              }
              default:
                throw new Error("Invalid filter id");
            }
          });
          break;
        }

        case AWS_REGIONS_FILTER_ID: {
          appliedItems.forEach((i) => {
            const filterId = i.id.split(":")[1];

            filteredServices.push(
              services.filter((s) => s.identifier.endsWith(filterId))
            );
          });
          break;
        }

        default:
          break;
      }
    });
  }

  const flattened = filteredServices.reduce((acc, val) => [...acc, ...val], []);
  const uniqueServices = flattened.reduce((acc, val) => {
    const existing = acc.find((a) => a.identifier === val.identifier);

    if (existing) return acc;

    return [...acc, val];
  }, []);

  // filter unique results by text search if it exists
  let textSearchServices = uniqueServices;
  if (textSearchValue) {
    const search = fuse.search(textSearchValue);
    const filteredOptions = search.map((s) => ({
      id: s.item.identifier,
      label: s.item.title,
    }));

    const textSearchResult = textSearchServices.filter((s) =>
      filteredOptions.find((o) => o.id === s.identifier)
    );

    textSearchServices = textSearchResult;
  }

  const sorted = textSearchServices.sort((a, b) =>
    a.title.localeCompare(b.title)
  );

  return sorted;
}
