import { useEffect, useState } from "react";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";

import { useUnsubscribeServicesMutation } from "lib/network";
import { ROUTES } from "lib/routes";

import PageLoader from "components/Loader/PageLoader";
import ResultModal from "components/ResultModal/ResultModal";

export default function UnsubscribeServicesPage() {
  const [ready, setReady] = useState(false);
  const {
    isLoading,
    error,
    mutate: unsubscribeServices,
  } = useUnsubscribeServicesMutation({
    async onSuccess(data) {
      setReady(true);

      if (!data.ok) {
        const json = await data.json();
        const message = /invalid token/i.test(json.message)
          ? "Your unsubscribe link has expired. Please use the Notifications page to update your subscriptions."
          : "Something went wrong";

        throw new Error(message);
      }
    },
  });

  useEffect(() => {
    function unsubscribe() {
      const urlParams = new URLSearchParams(window.location.search);
      const token = urlParams.get("token");

      unsubscribeServices(token);
    }

    unsubscribe();
  }, [unsubscribeServices]);

  if (!ready || isLoading) return <PageLoader />;

  return error ? (
    <ResultModal
      closeRoute={ROUTES.NOTIFICATIONS.path}
      Icon={CloseIcon}
      iconColor="error.light"
      heading="Error"
      subheading={error.message}
    />
  ) : (
    <ResultModal
      closeRoute={ROUTES.NOTIFICATIONS.path}
      Icon={CheckIcon}
      iconColor="success.light"
      heading="Success!"
      subheading="You have been unsubscribed."
    />
  );
}
