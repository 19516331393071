import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { Amplify, Hub } from "aws-amplify";

import { AppContextProvider } from "contexts";
import config from "lib/config";
import { OAUTH_ROUTE, ROUTES } from "lib/routes";

import AccountPage from "containers/AccountPage/AccountPage";
import AppLayout from "containers/AppLayout/AppLayout";
import DonationsPage from "containers/DonationsPage/DonationsPage";
import HomePage from "containers/HomePage/HomePage";
import NotFoundPage from "containers/NotFoundPage/NotFoundPage";
import SettingsPage from "containers/NotificationsPage/NotificationsPage";
import PrivacyPage from "containers/PrivacyPage/PrivacyPage";
import SlackAuthPage from "containers/SlackAuthPage/SlackAuthPage";
import TermsPage from "containers/TermsPage/TermsPage";

const replaceState = window.history.replaceState;

Amplify.configure(config);

if (window.location.pathname.startsWith(OAUTH_ROUTE)) {
  window.history.replaceState = () => false;
}

Hub.listen("auth", (data) => {
  if (data.payload.event === "signIn_failure") {
    window.history.replaceState = replaceState;
  }
});

export default function AuthenticatedApp() {
  return (
    <AppContextProvider>
      <AppLayout>
        <Routes>
          <Route path={ROUTES.ACCOUNT.path} element={<AccountPage />} />
          <Route path={ROUTES.HOME.path} element={<HomePage />} />
          <Route path={ROUTES.DONATIONS.path} element={<DonationsPage />} />
          <Route path={ROUTES.PRIVACY.path} element={<PrivacyPage />} />
          <Route path={ROUTES.NOTIFICATIONS.path} element={<SettingsPage />} />
          <Route
            path={ROUTES.SIGNUP.path}
            element={<Navigate to={ROUTES.HOME.path} replace />}
          />
          <Route path={ROUTES.SLACK_AUTH.path} element={<SlackAuthPage />} />
          <Route path={ROUTES.TERMS.path} element={<TermsPage />} />

          {/* NOTE: anonymous routes return false to use AnonymousRoutes as fallback without showing 404 page */}
          <Route path={ROUTES.UNSUBSCRIBE.path} element={false} />
          <Route path={ROUTES.UNSUBSCRIBE_ALL.path} element={false} />
          <Route path={ROUTES.VERIFY_EMAIL.path} element={false} />

          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </AppLayout>
    </AppContextProvider>
  );
}
