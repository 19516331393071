import React, { useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  Container,
  FormControlLabel,
  Grid,
  Link,
  Paper,
  Typography,
} from "@mui/material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

import { getPublicAsset } from "lib/assets";
import { getGoogleSignupUrl, getSignupUrl } from "lib/auth";
import { termsCopy } from "lib/terms";

export default function SignupPage() {
  const [accepted, setAccepted] = useState(false);
  const searchParams = new URLSearchParams(window.location.search);
  const signupType = searchParams && searchParams.get("type");
  const isGoogleSignup = Boolean(
    signupType && signupType.toLowerCase() === "google"
  );
  const signupHref = isGoogleSignup ? getGoogleSignupUrl() : getSignupUrl();

  const handleAcceptedChange = (_, newValue) => {
    setAccepted(newValue);
  };

  return (
    <Box
      sx={{
        position: "absolute",
        top: 0,
        left: 0,
        height: "100%",
        width: "100%",
        backgroundColor: "grey.600",
        zIndex: "mobileStepper",
      }}
    >
      <Container
        maxWidth="md"
        sx={{
          flex: "1 1 auto",
          display: "flex",
          flexDirection: "column",
          height: "100%",
          py: 4,
        }}
      >
        <Paper
          elevation={4}
          sx={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
            p: 3,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              flex: "1 1 auto",
              overflow: "hidden",
            }}
          >
            <Typography
              variant="h5"
              fontWeight="bold"
              sx={{ display: "flex", alignItems: "center" }}
            >
              <Box sx={{ width: "32px", height: "32px", mr: 1, mb: 1 }}>
                <img
                  src={getPublicAsset("logo192.png")}
                  alt="statusvista"
                  style={{ height: "100%" }}
                />
              </Box>
              Create Account
            </Typography>
            <Typography variant="subtitle1" sx={{ mb: 2 }}>
              Let's get this legal stuff out of the way. Please accept our Terms
              and Conditions to continue.
            </Typography>
            <Box
              sx={{
                flex: "1 1 auto",
                overflow: "hidden",
                borderWidth: 1,
                borderStyle: "solid",
                backgroundColor: "grey.50",
                borderColor: "grey.300",
                borderRadius: 1,
              }}
            >
              <Box
                sx={{
                  height: "100%",
                  overflowY: "scroll",
                  p: 2,
                }}
              >
                {termsCopy}
              </Box>
            </Box>
            <Grid container sx={{ mt: 2 }}>
              <Grid item xs={12} sm={6}>
                <FormControlLabel
                  label="I have read and agree to these terms"
                  control={
                    <Checkbox
                      checked={accepted}
                      size="small"
                      onChange={handleAcceptedChange}
                    />
                  }
                  sx={{ whiteSpace: "nowrap", mb: { xs: 1, sm: 0 } }}
                />
              </Grid>
              <Grid item xs={12} sm={6} sx={{ textAlign: "right" }}>
                <Box>
                  {accepted ? (
                    <Link href={signupHref}>
                      <Button
                        aria-label="proceed"
                        variant="contained"
                        disabled={!accepted}
                        sx={{ width: { xs: "100%", sm: "auto" } }}
                      >
                        Continue <ChevronRightIcon sx={{ ml: 1 }} />
                      </Button>
                    </Link>
                  ) : (
                    <Button
                      aria-label="proceed"
                      variant="contained"
                      disabled
                      sx={{ width: { xs: "100%", sm: "auto" } }}
                    >
                      Continue <ChevronRightIcon sx={{ ml: 1 }} />
                    </Button>
                  )}
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Paper>
      </Container>
    </Box>
  );
}
