import { Alert, AlertTitle, Box, Button, Skeleton, Stack } from "@mui/material";

import { useSubscriptionQuery } from "lib/network";

import { Link } from "react-router-dom";
import { ROUTES } from "lib/routes";

import ChannelSettings from "./ChannelSettings";
import ServiceSettings from "./ServiceSettings";
import PageTitle from "components/PageTitle/PageTitle";

export default function NotificationsPage() {
  const { data: subscriptionData, isLoading: isSubscriptionLoading } =
    useSubscriptionQuery();

  return (
    <Box>
      <PageTitle title="Notifications" sx={{ mb: 2 }} />
      {isSubscriptionLoading ? (
        <Stack spacing={1} sx={{ maxWidth: "sm" }}>
          <Skeleton animation="wave" variant="rectangular" height={24} />
          <Skeleton animation="wave" variant="rectangular" height={20} />
          <Stack direction="row" spacing={1}>
            <Skeleton
              animation="wave"
              variant="rectangular"
              width={120}
              height={39}
            />
            <Skeleton
              animation="wave"
              variant="rectangular"
              height={39}
              sx={{ flex: "1 1 auto" }}
            />
          </Stack>
          <Stack direction="row" spacing={1}>
            <Skeleton
              animation="wave"
              variant="rectangular"
              width={120}
              height={39}
            />
            <Skeleton
              animation="wave"
              variant="rectangular"
              height={39}
              sx={{ flex: "1 1 auto" }}
            />
          </Stack>
        </Stack>
      ) : (
        <Box>
          {!subscriptionData?.latest_subscription?.subscription_details ? (
            <Box sx={{ maxWidth: "md" }}>
              <Alert severity="info">
                <AlertTitle>Exclusive feature</AlertTitle>
                Notifications require an upgraded subscription. Visit your
                account for upgrade options &ndash;{" "}
                <strong>Start your free trial today!</strong>
                <Box sx={{ mt: 2 }}>
                  <Link to={ROUTES.ACCOUNT.path}>
                    <Button variant="outlined">Go to account</Button>
                  </Link>
                </Box>
              </Alert>
            </Box>
          ) : (
            <Box sx={{ maxWidth: { md: "sm" } }}>
              <ChannelSettings />
              <ServiceSettings />
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
}
