import { createContext, useContext } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";

import { useNotification } from "./notifications";
import { useStatusData } from "./status";
import { ENDPOINTS, QUERIES, destroy, get, post } from "lib/network";

import PageLoader from "components/Loader/PageLoader";

const ServicesDataContext = createContext();

export function useServicesData() {
  return useContext(ServicesDataContext);
}

export function ServicesDataProvider({ children }) {
  const queryClient = useQueryClient();
  const { notify } = useNotification();
  const { deleteStatus } = useStatusData();
  const {
    isLoading: isGetServicesLoading,
    error: getServicesError,
    data: getServicesData = [],
  } = useQuery(
    QUERIES.SERVICES.GET.ALL,
    () => get(ENDPOINTS.SERVICES.GET.ALL),
    {
      onError(error) {
        notify(error.message);
      },
    }
  );
  const {
    // isLoading: isPostServicesLoading,
    // error: postServicesError,
    // data: postServicesData,
    mutate: createServices,
  } = useMutation(
    (body) => post(ENDPOINTS.SERVICES.POST.USER, { body: { services: body } }),
    {
      onSuccess() {
        notify("Services added");
        queryClient.invalidateQueries(QUERIES.SERVICES.GET.ALL);
        queryClient.invalidateQueries(QUERIES.STATUS);
      },
      onError(error) {
        notify(error.message);
      },
    }
  );
  const {
    // isLoading: isDeleteServicesLoading,
    // error: deleteServicesError,
    // data: deleteServicesData,
    mutate: deleteService,
  } = useMutation(
    (body) =>
      destroy(ENDPOINTS.SERVICES.DELETE.ONE, { body: { service_id: body } }),
    {
      onSuccess(_, identifier) {
        notify("Service removed");
        deleteStatus(identifier);
      },
      onError(error) {
        notify(error.message);
      },
    }
  );

  if (isGetServicesLoading) return <PageLoader />;
  if (getServicesError) return <div>Error: {getServicesError}</div>;

  return (
    <ServicesDataContext.Provider
      value={{ services: getServicesData, createServices, deleteService }}
    >
      {children}
    </ServicesDataContext.Provider>
  );
}
