import React, { useEffect, useState } from "react";
import { Auth } from "aws-amplify";
import { matchPath, useLocation, useMatch } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";

import { useAuth } from "contexts/auth";
import { getAboutUrl, getLoginUrl } from "lib/auth";
import { useIsDemo } from "lib/config";
import { initMocks } from "lib/mocks";
import { AnonymousRoutes, ROUTES } from "lib/routes";

import AuthenticatedApp from "./AuthenticatedApp";
import DemoApp from "./DemoApp";
import PageLoader from "components/Loader/PageLoader";

const queryClient = new QueryClient();

export default function App() {
  const { signin, user } = useAuth();
  const { isDemo } = useIsDemo();
  const [userFetched, setUserFetched] = useState(false);
  const loggingOut = useMatch(ROUTES.LOGOUT.path);
  const signingUp = useMatch(ROUTES.SIGNUP.path);
  const { pathname } = useLocation();
  const anonymousRoutes = Object.keys(ROUTES)
    .filter((key) => ROUTES[key].anonymous)
    .map((route) => ROUTES[route]);

  useEffect(() => {
    async function getUser() {
      if (isDemo) {
        initMocks();
        return setUserFetched(true);
      }

      try {
        const user = await Auth.currentAuthenticatedUser();
        signin(user);
      } catch (e) {
        if (anonymousRoutes.some((route) => matchPath(pathname, route.path))) {
          return;
        }

        if (loggingOut) {
          window.location.replace(getAboutUrl());
        } else if (!signingUp) {
          window.location.replace(getLoginUrl());
        }
      } finally {
        setUserFetched(true);
      }
    }

    getUser();
  }, []); // eslint-disable-line

  useEffect(() => {
    const currentRoute = Object.keys(ROUTES).find(
      (route) => !!matchPath(pathname, ROUTES[route].path)
    );

    if (currentRoute) {
      const { pageTitle } = ROUTES[currentRoute];
      document.title = pageTitle;
    }

    window.scrollTo(0, 0);
  }, [pathname]);

  if (!userFetched) return <PageLoader />;

  return (
    <QueryClientProvider client={queryClient}>
      {isDemo ? <DemoApp /> : !!user && <AuthenticatedApp />}
      <AnonymousRoutes />
    </QueryClientProvider>
  );
}
