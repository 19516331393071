import { Typography } from "@mui/material";

export default function PageTitle({ title, sx = {} }) {
  return (
    <Typography
      variant="h5"
      component="h1"
      sx={{ fontWeight: "bold", mb: { xs: 1, sm: 0 }, ...sx }}
    >
      {title}
    </Typography>
  );
}
