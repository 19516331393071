import { createTheme, responsiveFontSizes } from "@mui/material/styles";

let baseTheme = createTheme({
  // https://material.io/inline-tools/color/
  palette: {
    primary: {
      main: "#427aa1", // steel blue
      50: "#e4f5fa",
      100: "#bbe5f3",
      200: "#92d5eb",
      300: "#73c4e2",
      400: "#62b7dc",
      500: "#58abd7",
      600: "#509dc9",
      700: "#478ab5",
      800: "#4279a1",
      900: "#365a7e",
    },
    secondary: {
      main: "#ffc107", // mui amber 500
      50: "#fff8e1",
      100: "#ffecb3",
      200: "#ffe082",
      300: "#ffd54f",
      400: "#ffca28",
      500: "#ffc107",
      600: "#ffb300",
      700: "#ffa000",
      800: "#ff8f00",
      900: "#ff6f00",
    },
  },
  components: {
    MuiAccordion: {
      defaultProps: {
        disableGutters: true,
      },
      styleOverrides: {
        root: {
          "&:before": {
            display: "none",
          },
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          "&:hover": {
            cursor: "default !important",
          },
        },
      },
    },
    MuiBadge: {
      styleOverrides: {
        root: {
          userSelect: "none",
        },
      },
    },
    MuiButton: {
      defaultProps: {
        disableRipple: true,
      },
      styleOverrides: {
        root: {
          borderRadius: 0,
          whiteSpace: "nowrap",
        },
      },
      variants: [
        {
          props: { variant: "contained" },
          style: { boxShadow: "none", "&:hover": { boxShadow: "none" } },
        },
      ],
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          userSelect: "none",
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          textDecoration: "none",
        },
      },
    },
    MuiListItemButton: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiModal: {
      styleOverrides: {
        root: {
          "& .MuiBox-root": {
            outline: "none",
          },
        },
      },
    },
  },
});

baseTheme = responsiveFontSizes(baseTheme);

export const theme = baseTheme;
