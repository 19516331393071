import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import SearchIcon from "@mui/icons-material/Search";
import InputAdornment from "@mui/material/InputAdornment";
import CircularProgress from "@mui/material/CircularProgress";
import Fuse from "fuse.js";
import { IconButton } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";

import { useStatusData } from "contexts/status";

import PageTitle from "components/PageTitle/PageTitle";
import StatusList from "components/StatusList/StatusList";

const SEARCH_INPUT_ID = "status-search-input";
const fuseOptions = {
  threshold: 0.4,
  keys: ["identifier", "title"],
};

export default function HomePage() {
  const [fuse, setFuse] = useState();
  const [searchValue, setSearchValue] = useState("");
  const [statusState, setStatusState] = useState({
    ready: false,
    items: [],
  });
  const { status } = useStatusData();

  const handleClearInput = () => {
    handleSearchChange({ target: { value: "" } });
  };

  const handleSearchChange = (event) => {
    setSearchValue(event.target.value);
  };

  useEffect(() => {
    if (!fuse) {
      setFuse(new Fuse(status, fuseOptions));
    } else {
      fuse.setCollection(status);
    }
  }, [fuse, status]);

  useEffect(() => {
    const searchValueExists = !!searchValue.length;

    if (!searchValueExists) {
      return setStatusState({ ready: true, items: status });
    }

    setStatusState({ ready: false, items: status });
    setTimeout(() => {
      const currentValue = document.getElementById(SEARCH_INPUT_ID).value;
      if (currentValue === searchValue) {
        const searchResult = fuse.search(searchValue);

        setStatusState({
          ready: true,
          items: searchResult.map((r) => r.item),
        });
      }
    }, 200);
  }, [fuse, searchValue, status]);

  return (
    <Box>
      <Grid container sx={{ mb: status.length ? 1 : 2 }}>
        <Grid item xs={12} sm={7} sx={{ display: "flex" }}>
          <PageTitle title="Dashboard" />
        </Grid>
        <Grid item xs={12} sm={5}>
          {status.length > 0 && (
            <TextField
              label="Search"
              fullWidth
              size="small"
              InputProps={{
                id: SEARCH_INPUT_ID,
                endAdornment: (
                  <InputAdornment position="end">
                    {searchValue.length > 0 && (
                      <IconButton
                        aria-label="clear search"
                        onClick={handleClearInput}
                        size="small"
                        sx={{ mr: 1 }}
                      >
                        <ClearIcon />
                      </IconButton>
                    )}
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              value={searchValue}
              onChange={handleSearchChange}
            />
          )}
        </Grid>
      </Grid>
      {statusState.ready ? (
        <StatusList statusState={statusState} searchValue={searchValue} />
      ) : (
        <CircularProgress />
      )}
    </Box>
  );
}
