import { NotificationProvider } from "./notifications";
import { ServicesDataProvider } from "./services";
import { StatusDataProvider } from "./status";

const providers = [
  NotificationProvider,
  StatusDataProvider,
  ServicesDataProvider,
];

export const AppContextProvider = providers.reduce(
  (AccumulatedComponents, CurrentComponent) => {
    return ({ children }) => (
      <AccumulatedComponents>
        <CurrentComponent>{children}</CurrentComponent>
      </AccumulatedComponents>
    );
  },
  ({ children }) => <>{children}</>
);
