import { useEffect, useState } from "react";
import { useMutation } from "react-query";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";

import { ENDPOINTS } from "lib/network";
import { ROUTES } from "lib/routes";

import PageLoader from "components/Loader/PageLoader";
import ResultModal from "components/ResultModal/ResultModal";

export default function VerifyEmailPage() {
  const [ready, setReady] = useState(false);
  const {
    isLoading,
    error,
    mutate: verifyEmail,
  } = useMutation(
    // use fetch here for anonymous route
    (token) => fetch(ENDPOINTS.ACCOUNT.VERIFY_EMAIL(token)),
    {
      async onSuccess(data) {
        setReady(true);

        if (!data.ok) {
          const json = await data.json();
          const message = /invalid token/i.test(json.message)
            ? "Your verification link has expired. Please request another one from the Account page."
            : "Something went wrong";

          throw new Error(message);
        }
      },
    }
  );

  useEffect(() => {
    async function verify() {
      const urlParams = new URLSearchParams(window.location.search);
      const token = urlParams.get("token");

      verifyEmail(token);
    }

    verify();
  }, [verifyEmail]);

  if (!ready || isLoading) return <PageLoader />;

  return error ? (
    <ResultModal
      closeRoute={ROUTES.ACCOUNT.path}
      Icon={CloseIcon}
      iconColor="error.light"
      heading="Error"
      subheading={error.message}
    />
  ) : (
    <ResultModal
      closeRoute={ROUTES.ACCOUNT.path}
      Icon={CheckIcon}
      iconColor="success.light"
      heading="Verified!"
      subheading="You have successfully verified your account."
    />
  );
}
