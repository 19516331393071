import { Auth } from "aws-amplify";

async function getAccessToken() {
  try {
    const session = await Auth.currentSession();
    return session.getAccessToken().getJwtToken();
  } catch (e) {
    // dummy token for demo app
    return "xxxx";
  }
}

async function getNetworkResponse(result) {
  const json = await result.json();

  if (!result.ok) {
    const { errors, message: jsonMessage = "Something went wrong" } = json;
    const errorMessage =
      errors && errors.length > 0
        ? `${result.statusText}: ${errors.map((e) => e.msg).join(",")}`
        : jsonMessage;

    throw new Error(errorMessage);
  }

  return json;
}

export async function get(url, options = {}) {
  const accessToken = await getAccessToken();
  const result = await fetch(url, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/json",
    },
    ...options,
  });

  return getNetworkResponse(result);
}

export async function post(url, options = {}) {
  const { body, ...optionsRest } = options;
  const accessToken = await getAccessToken();
  const result = await fetch(url, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
    ...optionsRest,
  });

  return getNetworkResponse(result);
}

export async function destroy(url, options = {}) {
  const { body, ...optionsRest } = options;
  const accessToken = await getAccessToken();
  const result = await fetch(url, {
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
    ...optionsRest,
  });

  return getNetworkResponse(result);
}

export * from "./endpoints";
export * from "./queries";
