import React, { useState } from "react";
import { useQuery } from "react-query";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import IconButton from "@mui/material/IconButton";
import Link from "@mui/material/Link";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ClearIcon from "@mui/icons-material/Clear";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/system";
import { alpha, Skeleton, Stack } from "@mui/material";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { merge } from "lodash";
import moment from "moment-timezone";

import { useNotification } from "contexts/notifications";
import { useServicesData } from "contexts/services";
import { useStatusData } from "contexts/status";
import { ENDPOINTS, QUERIES, get } from "lib/network";
import { getStatus, getStatusFromValue, STATUS } from "lib/status";

import StatusIndicator from "components/StatusIndicator/StatusIndicator";
import { TIME_FORMATS } from "lib/time";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const staticChartOptions = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      displayColors: false,
      callbacks: {
        title: function ([
          {
            raw: { x },
          },
        ]) {
          return moment.unix(x).format(TIME_FORMATS.FULL);
        },
      },
    },
  },
  scales: {
    x: {
      type: "linear",
      display: true,
      scaleLabel: {
        display: true,
      },
      ticks: {
        stepSize: 60 * 60 * 24 * 7, // 7 days (seconds)
        callback: function (value) {
          return moment.unix(value).format("MM/DD");
        },
      },
    },
  },
};

export default function StatusItem({ item, divider }) {
  const [statusAnchorEl, setStatusAnchorEl] = useState();
  const [expanded, setExpanded] = React.useState(false);
  const [selectedServiceId, setSelectedServiceId] = useState();
  const [changesChartData, setChangesChartData] = useState();
  const buttonId = `status-button-${item.identifier}`;
  const menuOpen = !!(statusAnchorEl && statusAnchorEl.id === buttonId);
  const { deleteService } = useServicesData();
  const { status } = useStatusData();
  const currentStatus =
    status.find((s) => s.identifier === item.identifier) || {};
  const theme = useTheme();
  const { notify } = useNotification();
  const {
    isFetching,
    isLoading,
    data,
    refetch: getService,
  } = useQuery(
    QUERIES.SERVICE(item.identifier),
    () => get(ENDPOINTS.SERVICE(item.identifier)),
    {
      enabled: false,
      onError(error) {
        notify(error.message);
      },
      onSuccess(data) {
        const startTimeUnix = moment(data.status_changes.start_time).unix();
        const endTimeUnix = moment().unix();
        const chartOptions = merge(staticChartOptions, {
          plugins: {
            tooltip: {
              callbacks: {
                label: function (context) {
                  const { dataIndex, dataset } = context;
                  let changeItem;

                  if (dataIndex === dataset.data.length - 1) {
                    changeItem = currentStatus;
                  } else {
                    changeItem = data.status_changes.items.find(
                      (i) =>
                        moment(i.created_at).unix() ===
                        dataset.data[dataIndex].x
                    );
                  }

                  const label = getStatus(
                    data.status_source_type,
                    changeItem.status_indicator
                  ).label;
                  const description = changeItem.status_description;

                  return `${label}${
                    description.length > 0 ? ` - ${description}` : ""
                  }`;
                },
              },
            },
          },
          scales: {
            x: {
              min: startTimeUnix,
              max: endTimeUnix,
            },
            y: {
              min: 0,
              max: Object.keys(STATUS[data.status_source_type]).length - 1,
              ticks: {
                stepSize: 1,
                callback: function (value) {
                  const statusValue = getStatusFromValue(
                    data.status_source_type,
                    value
                  );
                  return statusValue ? statusValue.label : "";
                },
              },
            },
          },
        });

        const points = [
          ...data.status_changes.items.map((i) => ({
            x: moment(i.created_at).unix(),
            y: getStatus(data.status_source_type, i.status_indicator)
              .chartValue,
          })),
          {
            x: moment().unix(),
            y: getStatus(
              currentStatus.status_source_type,
              currentStatus.status_indicator
            ).chartValue,
          },
        ];
        const chartData = {
          datasets: [
            {
              label: "Status History",
              data: points,
              borderColor: theme.palette.primary.light,
              backgroundColor: alpha(theme.palette.primary.light, 0.5),
              pointStyle: "circle",
              pointRadius: 4,
              pointHoverRadius: 8,
              tension: 0.025,
            },
          ],
        };

        setChangesChartData({
          data: chartData,
          options: chartOptions,
        });
      },
    }
  );

  const ExportButton = () => {
    if (!data) return false;

    const exportData = {
      ...data,
      status_changes: {
        ...data.status_changes,
        items: [
          ...data.status_changes.items,
          {
            created_at: currentStatus.updated_at,
            status_indicator: currentStatus.status_indicator,
            status_description: currentStatus.status_description,
          },
        ],
      },
    };

    return (
      <Link
        aria-label="export history"
        href={`data:text/json;charset=utf-8,${encodeURIComponent(
          JSON.stringify(exportData, null, 2)
        )}`}
        download={`sv_${data.identifier}_${moment().unix()}.json`}
        sx={{ display: "flex", alignItems: "center" }}
      >
        Export history
        <FileDownloadIcon fontSize="small" sx={{ ml: 0.5 }} />
      </Link>
    );
  };

  const handleMenuClick = (serviceId) => (event) => {
    event.stopPropagation();
    setSelectedServiceId(serviceId);
    setStatusAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setStatusAnchorEl(null);
  };

  const handleRemoveClick = () => {
    deleteService(selectedServiceId);
    handleMenuClose();
  };

  const handleToggleExpand = () => {
    const newExpanded = !expanded;
    setExpanded(newExpanded);

    if (newExpanded) {
      getService();
    }
  };

  return (
    <Accordion
      expanded={expanded}
      elevation={0}
      sx={{
        "&:after": divider
          ? {
              content: "''",
              position: "absolute",
              left: 0,
              top: "-1px",
              right: 0,
              height: "1px",
              backgroundColor: theme.palette.divider,
            }
          : {},
      }}
    >
      <AccordionSummary
        aria-controls={`${item.identifier}-content`}
        id={`${item.identifier}-header`}
        expandIcon={false}
        sx={{
          cursor: "default",
          "& .MuiAccordionSummary-content": {
            width: "100%",
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: { xs: "start", lg: "center" },
            width: "100%",
          }}
        >
          <IconButton
            aria-label="expand service"
            onClick={handleToggleExpand}
            sx={{ mr: 1 }}
          >
            <ArrowForwardIosSharpIcon
              sx={{
                fontSize: "0.9rem",
                transition: `all ${theme.transitions.duration.shortest}ms ${theme.transitions.easing.easeInOut}`,
                transform: expanded ? "rotate(90deg)" : "rotate(0)",
              }}
            />
          </IconButton>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              width: "100%",
              overflow: "hidden",
            }}
          >
            <Grid
              container
              spacing={1}
              sx={{ alignItems: "center", overflow: "hidden" }}
            >
              <Grid
                item
                xs={12}
                lg={6}
                xl={5}
                sx={{
                  mb: { xs: 1, md: 0 },
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                <Link
                  href={item.status_page_url}
                  target="_blank"
                  rel="noreferrer"
                >
                  <Typography
                    sx={{
                      display: "inline",
                      overflow: "hidden",
                      width: "100%",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {item.title}
                  </Typography>
                </Link>
              </Grid>
              <Grid item xs={12} lg={6} xl="auto">
                <StatusIndicator {...item} />
              </Grid>
            </Grid>
            <Box sx={{ ml: 2 }}>
              <IconButton
                id={buttonId}
                aria-label="status menu"
                aria-controls={
                  menuOpen ? `status-menu-${item.identifier}` : undefined
                }
                aria-haspopup="true"
                aria-expanded={menuOpen ? "true" : undefined}
                onClick={handleMenuClick(item.identifier)}
              >
                <MoreVertIcon />
              </IconButton>
              <Menu
                id={`status-menu-${item.identifier}`}
                anchorEl={statusAnchorEl}
                open={menuOpen}
                onClose={handleMenuClose}
                MenuListProps={{
                  "aria-labelledby": buttonId,
                }}
                anchorOrigin={{
                  vertical: "center",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "center",
                  horizontal: "right",
                }}
              >
                <MenuItem onClick={handleRemoveClick}>
                  <ListItemIcon>
                    <ClearIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText>Remove service</ListItemText>
                </MenuItem>
              </Menu>
            </Box>
          </Box>
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        <Box>
          {isFetching || isLoading ? (
            <Stack spacing={1}>
              <Skeleton animation="wave" variant="rectangular" height={20} />
              <Skeleton animation="wave" variant="rectangular" height={135} />
              <Stack direction="row" spacing={1}>
                <Skeleton
                  animation="wave"
                  variant="rectangular"
                  height={60}
                  sx={{ flex: "1 1 auto" }}
                />
                <Skeleton
                  animation="wave"
                  variant="rectangular"
                  height={60}
                  sx={{ flex: "1 1 auto" }}
                />
              </Stack>
            </Stack>
          ) : (
            data && (
              <Box>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Box sx={{ display: "flex" }}>
                      <Typography
                        fontWeight="bold"
                        variant="caption"
                        sx={{ flex: "1 1 auto" }}
                      >
                        60-day incident history
                      </Typography>
                      <Typography variant="body2">
                        {changesChartData && <ExportButton />}
                      </Typography>
                    </Box>
                    <Box>
                      {changesChartData && (
                        <Line
                          options={changesChartData.options}
                          data={changesChartData.data}
                        />
                      )}
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography fontWeight="bold" variant="caption">
                      60-day uptime
                    </Typography>
                    <Typography variant="body2">
                      {`${data.uptime_percentage}%`}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography fontWeight="bold" variant="caption">
                      Last updated
                    </Typography>
                    <Typography variant="body2">
                      {moment(data.updated_at).format(TIME_FORMATS.FULL)}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            )
          )}
        </Box>
      </AccordionDetails>
    </Accordion>
  );
}
