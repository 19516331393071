import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { visuallyHidden } from "@mui/utils";
import { Stack } from "@mui/material";

export const SERVICES_MODAL_TITLE_ID = "services-modal-title";
export const SERVICES_MODAL_DESCRIPTION_ID = "services-modal-description";

export function ModalHeader() {
  return (
    <Stack
      data-testid="modal-header"
      direction="row"
      sx={{ width: "100%", mb: { md: 1 } }}
    >
      <Box sx={{ flex: "1 1 auto" }}>
        <Typography id={SERVICES_MODAL_TITLE_ID} variant="h6" component="h2">
          Add New Services
        </Typography>
        <Typography
          id={SERVICES_MODAL_DESCRIPTION_ID}
          variant="subtitle2"
          sx={visuallyHidden}
        >
          Select which services you want to add.
        </Typography>
      </Box>
    </Stack>
  );
}

export function ModalActions({
  handleFormSubmit,
  handleModalClose,
  selectedServices,
}) {
  return (
    <Stack direction="row" sx={{ justifyContent: "end", mt: 2 }}>
      <Button aria-label="cancel" variant="outlined" onClick={handleModalClose}>
        Cancel
      </Button>
      <Button
        aria-label="add selected services"
        onClick={handleFormSubmit}
        sx={{ ml: 1 }}
        variant="contained"
        disabled={!selectedServices.length}
      >
        Add selected ({selectedServices.length})
      </Button>
    </Stack>
  );
}
