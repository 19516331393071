import React, { useRef, useState } from "react";
import Box from "@mui/material/Box";
import AddIcon from "@mui/icons-material/Add";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import { merge } from "lodash";
import {
  Grid,
  Slide,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";

import { allFilters } from ".";
import { FilterButton, Filters } from "./Filters";
import ServicesList from "./ServicesList";
import {
  ModalActions,
  ModalHeader,
  SERVICES_MODAL_DESCRIPTION_ID,
  SERVICES_MODAL_TITLE_ID,
} from "./ServicesModal";
import { useServicesData } from "contexts/services";

export default function ServicesButton({ buttonProps = {} }) {
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedServices, setSelectedServices] = useState([]);
  const [filtering, setFiltering] = useState(false);
  const [filters, setFilters] = useState({ values: {}, applied: [] });
  const [inputValue, setInputValue] = useState("");
  const { createServices } = useServicesData();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const filterContainerRef = useRef(null);
  const filtersLength = Object.keys(filters.applied).length;

  const handleFilterChange = ({ target: { name } }, checked) => {
    const parts = name.split(":");
    const filterType = parts[0];
    const newFilters = merge(filters.values, {
      [filterType]: {
        [name]: checked,
      },
    });

    const applied = !!Object.keys(newFilters).length
      ? Object.keys(newFilters).reduce((acc, val) => {
          const enabled = Object.keys(newFilters[val]).filter(
            (v) => !!newFilters[val][v]
          );
          const visible = enabled.filter((e) => allFilters[val].visible);
          const additions = visible.map((t) =>
            allFilters[val].items.find((t1) => t1.id === t)
          );

          return [...acc, ...additions];
        }, [])
      : [];

    setFilters({
      values: newFilters,
      applied,
    });
  };

  const handleFilterDelete = (id) => () => {
    const parts = id.split(":");
    const filterType = parts[0];
    const newFilters = { ...filters };
    delete newFilters.values[filterType][id];
    const applied = newFilters.applied.filter((l) => l.id !== id);

    setFilters({
      values: newFilters.values,
      applied,
    });
  };

  const handleFilterClick = () => {
    setFiltering(!filtering);
  };

  const handleModalOpen = () => {
    setModalOpen(true);
  };

  const handleModalClose = (event, reason) => {
    if (selectedServices.length && reason && reason === "backdropClick") {
      return;
    }

    setModalOpen(false);
    setSelectedServices([]);
  };

  const handleFormSubmit = () => {
    createServices(selectedServices.map((s) => s.identifier));
    handleModalClose();
  };

  const handleServicesChange = (service, checked) => {
    if (checked) {
      setSelectedServices([...selectedServices, service]);
    } else {
      const newServices = [...selectedServices];
      newServices.splice(
        newServices.findIndex((s) => s.identifier === service.identifier),
        1
      );

      setSelectedServices(newServices);
    }
  };

  return (
    <Box>
      <Button
        id="service-button"
        aria-label="account menu"
        aria-controls={modalOpen ? "service-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={modalOpen ? "true" : undefined}
        variant="contained"
        endIcon={<AddIcon />}
        size="large"
        onClick={handleModalOpen}
        {...buttonProps}
      >
        Add Services
      </Button>
      <Modal
        open={modalOpen}
        onClose={handleModalClose}
        aria-labelledby={SERVICES_MODAL_TITLE_ID}
        aria-describedby={SERVICES_MODAL_DESCRIPTION_ID}
      >
        <Box
          sx={{
            py: { xs: 0, md: 2 },
            maxWidth: "md",
            mx: "auto",
            height: "100%",
          }}
        >
          <Box
            sx={{
              height: "100%",
              display: "flex",
              flexDirection: "column",
              bgcolor: "background.paper",
            }}
          >
            {isMobile ? (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  height: "100%",
                  p: 2,
                }}
              >
                {/* -------------------- MOBILE LAYOUT -------------------- */}
                <Box
                  ref={filterContainerRef}
                  sx={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    zIndex: "mobileStepper",
                    pointerEvents: filtering ? "auto" : "none",
                  }}
                >
                  <Slide
                    in={filtering}
                    direction="left"
                    container={filterContainerRef.current}
                  >
                    <Box sx={{ bgcolor: "background.default", height: "100%" }}>
                      <Filters
                        filters={filters}
                        handleFilterChange={handleFilterChange}
                        handleFilterClose={handleFilterClick}
                        handleFilterDelete={handleFilterDelete}
                      />
                    </Box>
                  </Slide>
                </Box>
                <ModalHeader />
                <FilterButton
                  filtersLength={filtersLength}
                  handleFilterClick={handleFilterClick}
                />
                <Box sx={{ flex: "1 1 auto" }}>
                  <ServicesList
                    filters={filters}
                    handleServicesChange={handleServicesChange}
                    inputValue={inputValue}
                    selectedServices={selectedServices}
                    setFilters={() => setFilters((updated) => ({ ...updated }))}
                    setInputValue={setInputValue}
                  />
                </Box>
                <ModalActions
                  handleFormSubmit={handleFormSubmit}
                  handleModalClose={handleModalClose}
                  selectedServices={selectedServices}
                />
              </Box>
            ) : (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  height: "100%",
                  px: 2,
                  pt: 2,
                }}
              >
                {/* -------------------- DESKTOP LAYOUT -------------------- */}
                <Grid
                  container
                  spacing={2}
                  sx={{ height: "100%", overflow: "hidden" }}
                >
                  <Grid
                    item
                    xs={4}
                    sx={{
                      height: "100%",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <ModalHeader />
                    <Box sx={{ mt: 1 }}>
                      <Typography variant="subtitle2" fontWeight="bold">
                        Filters ({filtersLength})
                      </Typography>
                    </Box>
                    <Filters
                      filters={filters}
                      handleFilterChange={handleFilterChange}
                      handleFilterClose={handleFilterClick}
                      handleFilterDelete={handleFilterDelete}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={8}
                    sx={{
                      height: "100%",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <ServicesList
                      filters={filters}
                      handleServicesChange={handleServicesChange}
                      inputValue={inputValue}
                      selectedServices={selectedServices}
                      setFilters={() =>
                        setFilters((updated) => ({ ...updated }))
                      }
                      setInputValue={setInputValue}
                    />
                    <ModalActions
                      handleFormSubmit={handleFormSubmit}
                      handleModalClose={handleModalClose}
                      selectedServices={selectedServices}
                    />
                  </Grid>
                </Grid>
              </Box>
            )}
          </Box>
        </Box>
      </Modal>
    </Box>
  );
}
