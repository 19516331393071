import React from "react";
import { useQuery } from "react-query";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import Button from "@mui/material/Button";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { visuallyHidden } from "@mui/utils";

import { ENDPOINTS, QUERIES, get } from "lib/network";

import PageLoader from "components/Loader/PageLoader";

export default function DonationsPage() {
  const { isLoading, error, data } = useQuery(QUERIES.DONATIONS, () =>
    get(ENDPOINTS.DONATIONS)
  );

  if (isLoading) return <PageLoader />;
  if (error) return error.message;

  return (
    <Box sx={{ py: 2 }}>
      <Typography variant="h5" component="h1" sx={{ fontWeight: "bold" }}>
        One-Time Donations
      </Typography>
      <Typography sx={{ mb: 2 }}>
        If you are finding StatusVista useful, please consider donating. Your
        donation will go a long way into maintaining and improving the app.
        Thank you!
      </Typography>
      <TableContainer component={Paper}>
        <Table aria-label="donations table">
          <TableHead>
            <TableRow>
              <TableCell sx={{ fontWeight: "bold" }}>Name</TableCell>
              <TableCell sx={{ fontWeight: "bold" }}>Amount</TableCell>
              <TableCell sx={{ fontWeight: "bold" }} align="right">
                <Typography sx={visuallyHidden}>Actions</Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((item) => (
              <TableRow key={item.id} item xs={12}>
                <TableCell>
                  <Typography sx={{ fontWeight: "bold" }}>
                    {item.title}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography sx={{ fontWeight: "bold", display: "inline" }}>
                    ${item.price}
                  </Typography>{" "}
                  <Typography sx={{ display: "inline" }}>
                    ({item.priceDescription})
                  </Typography>
                </TableCell>
                <TableCell align="right">
                  <Link href={item.link} target="_blank" rel="noreferrer">
                    <Button aria-label="select donation" primary>
                      Select
                    </Button>
                  </Link>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}
