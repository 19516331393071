import { useQuery } from "react-query";

import { ENDPOINTS, QUERIES, get } from "..";
import { useNotification } from "contexts/notifications";

export function useAccountQuery(options = {}) {
  const { notify } = useNotification();

  return useQuery(QUERIES.ACCOUNT.GET, () => get(ENDPOINTS.ACCOUNT.GET), {
    onError(error) {
      notify(error.message);
    },
    ...options,
  });
}
