import { Box, Typography } from "@mui/material";

export default function Descriptor({ details, heading, sx = {} }) {
  return (
    <Box sx={sx}>
      <Typography variant="caption" fontWeight="fontWeightBold">
        {heading}
      </Typography>
      <Typography component="div" variant="body2">
        {details}
      </Typography>
    </Box>
  );
}
